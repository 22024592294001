// eslint-disable-next-line import/prefer-default-export
import aboutEn from './about en.md';
import aboutFr from './about fr.md';
import annEn from './anne en.md';
import annFr from './anne fr.md';
import chaEnRef from './chantal en ref.md';
import chaEn from './chantal en.md';
import chaFrRef from './chantal fr ref.md';
import chaFr from './chantal fr.md';
import domEn from './dominic en.md';
import domFr from './dominic fr.md';
import fraEn from './francois en.md';
import fraFr from './francois fr.md';
import instructionsEn from './instructions en.md';
import instructionsFr from './instructions fr.md';
import josEn from './josue en.md';
import josFr from './josue fr.md';
import marEn from './mariechristine en.md';
import marFr from './mariechristine fr.md';
import natEn from './nathalie en.md';
import natFr from './nathalie fr.md';
import rejEn from './rejane en.md';
import rejFr from './rejane fr.md';
import selEn from './selma en.md';
import selFr from './selma fr.md';
import sheEn from './sherri en.md';
import sheFr from './sherri fr.md';
import steEn from './stephane en.md';
import steFr from './stephane fr.md';

export const anne = { en: annEn, fr: annFr };
export const francois = { en: fraEn, fr: fraFr };
export const josue = { en: josEn, fr: josFr };
export const mariechristine = { en: marEn, fr: marFr };
export const nathalie = { en: natEn, fr: natFr };
export const selma = { en: selEn, fr: selFr };
export const sherri = { en: sheEn, fr: sheFr };
export const stephane = { en: steEn, fr: steFr };
export const about = { en: aboutEn, fr: aboutFr };
export const instructions = { en: instructionsEn, fr: instructionsFr };
export const rejane = { en: rejEn, fr: rejFr };
export const chantal = { en: chaEn, fr: chaFr, ref: { en: chaEnRef, fr: chaFrRef } };
export const dominic = { en: domEn, fr: domFr };

/* eslint-disable react/no-danger */

/* eslint-disable jsx-a11y/tabindex-no-positive */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { about } from '../../data';
import Button from '../buttons/Button';
import ExternalLinkButton from '../buttons/ExternalLink';
import { textLogoFr, textLogoEn } from '../icons';
import PageTitle from '../partials/PageTitle';

import styles from '../../styles/pages/about.module.scss';

const propTypes = { menuOpened: PropTypes.bool, className: PropTypes.string };

const defaultProps = { menuOpened: false, className: null };

function AboutPage({ menuOpened, className }) {
    const url = useUrlGenerator();
    const { locale } = useIntl();
    const intl = useIntl();

    const { text, artists } = about;

    const focusable = !menuOpened;

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <PageTitle
                title={intl.formatMessage({
                    defaultMessage: 'About | Dream Voices',
                    description: 'Page title',
                })}
            />
            <div className={styles.inner}>
                <span className={styles.shadow} />
                <h1 className={styles.title}>
                    <FormattedMessage defaultMessage="About" definition="About title" />
                </h1>
                <div className={styles.text} dangerouslySetInnerHTML={{ __html: text[locale] }} />

                <h2 className={styles.sectionHeader}>
                    <FormattedMessage defaultMessage="The Artists" definition="Section header" />
                </h2>
                {artists.map(
                    (
                        {
                            id,
                            name,
                            role = null,
                            photo = null,
                            website = null,
                            bio = null,
                            reference = null,
                        },
                        index,
                    ) => (
                        <div
                            key={id}
                            className={classNames([
                                styles.artist,
                                {
                                    [styles.withPhoto]: photo !== null,
                                },
                            ])}
                        >
                            {photo ? (
                                <img
                                    src={photo}
                                    alt={name}
                                    className={styles.image}
                                    style={
                                        index % 2 === 0
                                            ? { transform: 'rotate(-7deg)' }
                                            : { transform: 'rotate(7deg)' }
                                    }
                                />
                            ) : null}
                            <div className={styles.artistInner}>
                                <h3 className={styles.name}>
                                    {name}
                                    {role ? (
                                        <span className={styles.role}> {role[locale]}</span>
                                    ) : null}
                                </h3>
                                <div
                                    className={styles.bio}
                                    dangerouslySetInnerHTML={{ __html: bio[locale] }}
                                />
                                {reference ? (
                                    <div
                                        className={styles.reference}
                                        dangerouslySetInnerHTML={{ __html: reference[locale] }}
                                    />
                                ) : null}

                                {website !== null
                                    ? website.map(({ link, text: webText }) => (
                                          // eslint-disable-next-line react/jsx-indent
                                          <div
                                              key={`link-${link}`}
                                              className={styles.linkContainer}
                                          >
                                              <ExternalLinkButton
                                                  href={link}
                                                  target="_blank"
                                                  external
                                                  screenReaderBefore={
                                                      locale === 'en' ? `${name}'s ` : null
                                                  }
                                                  screenReaderAfter={
                                                      locale === 'fr'
                                                          ? `de ${name} (ouvre une nouvelle fenêtre)`
                                                          : '(opens a new window)'
                                                  }
                                                  className={styles.linkButton}
                                                  focusable={focusable}
                                              >
                                                  {webText[locale]}
                                              </ExternalLinkButton>
                                          </div>
                                      ))
                                    : null}
                            </div>
                        </div>
                    ),
                )}
            </div>
            <Button href={url('home')} className={styles.logo} focusable={focusable}>
                <span className={styles.logoShadow} />
                <img
                    alt={intl.formatMessage({
                        defaultMessage: 'Dream Voices (back to Home)',
                        description: 'Logo label',
                    })}
                    src={locale === 'fr' ? textLogoFr : textLogoEn}
                    className={styles.logoIcon}
                />
            </Button>
        </div>
    );
}

AboutPage.propTypes = propTypes;
AboutPage.defaultProps = defaultProps;

export default AboutPage;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { restart } from '../icons';

import Button from './Button';
import styles from '../../styles/buttons/restart.module.scss';

const propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

const defaultProps = {
    children: null,
    className: null,
};

function RestartButton({ className, children, ...props }) {
    return (
        <Button
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            {...props}
        >
            <span className={styles.shadow} />
            <div className={styles.inner}>
                <img src={restart} alt="" className={styles.icon} aria-hidden />
                {children}
            </div>
        </Button>
    );
}

RestartButton.propTypes = propTypes;
RestartButton.defaultProps = defaultProps;

export default RestartButton;

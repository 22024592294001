import { RoutesProvider } from '@folklore/routes';
import PropTypes from 'prop-types';
import React, { useMemo, useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useMatch, generatePath } from 'react-router-dom';

import { routes as allRoutes } from '../data';

const defaultRichTextElements = {
    strong: (chunk) => <strong>{chunk}</strong>,
};

const propTypes = {
    path: PropTypes.string,
    locales: PropTypes.arrayOf(PropTypes.string),
    hostsByLocale: PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.instanceOf(RegExp), PropTypes.string]),
    ),
    children: PropTypes.node,
};

const defaultProps = {
    path: '/:locale',
    hostsByLocale: {
        fr: 'voix-revees.com',
        en: 'dream-voices.com',
    },
    locales: ['fr', 'en'],
    children: null,
};

function IntlRouter({ path, locales, hostsByLocale, children }) {
    const { hostname } = window.location;
    const isLocalhost = hostname === 'localhost';
    const match = useMatch(`${path}/*`);
    const { params: { locale: paramsLocale = 'fr' } = {} } = match || {};
    const locale = isLocalhost
        ? paramsLocale
        : Object.keys(hostsByLocale).find((it) => hostname === hostsByLocale[it]) || locales[0];

    const localizedRoutes = useMemo(() => {
        const routes = {
            ...allRoutes.default,
            ...allRoutes[locale],
        };
        return Object.keys(routes).reduce(
            (map, key) => ({
                ...map,
                [key]: `${
                    isLocalhost
                        ? generatePath(path, {
                              locale,
                          })
                        : ''
                }${routes[key] === '/' && isLocalhost ? '' : `${routes[key]}`}`,
            }),
            {
                ...locales.reduce((map, otherLocale) => {
                    const otherRoutes = {
                        ...allRoutes.default,
                        ...allRoutes[otherLocale],
                    };
                    return {
                        ...map,
                        ...Object.keys(otherRoutes).reduce(
                            (subMap, key) => ({
                                ...subMap,
                                [`${otherLocale}.${key}`]: `${
                                    isLocalhost
                                        ? generatePath(path, {
                                              locale: otherLocale,
                                          })
                                        : `https://${hostsByLocale[otherLocale]}`
                                }${
                                    otherRoutes[key] === '/' && isLocalhost
                                        ? ''
                                        : `${otherRoutes[key]}`
                                }`,
                            }),
                            map,
                        ),
                    };
                }, {}),
            },
        );
    }, [path, isLocalhost, hostsByLocale]);

    const [messages, setMessages] = useState(null);
    useEffect(() => {
        import(`../lang/${locale}.json`).then(({ default: newMessages }) =>
            setMessages(newMessages),
        );
    }, [locale]);

    return messages !== null ? (
        <IntlProvider
            locale={locale}
            messages={messages}
            defaultRichTextElements={defaultRichTextElements}
        >
            <RoutesProvider routes={localizedRoutes}>{children}</RoutesProvider>
        </IntlProvider>
    ) : null;
}

IntlRouter.propTypes = propTypes;
IntlRouter.defaultProps = defaultProps;

export default IntlRouter;

import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

// import * as AppPropTypes from '../../lib/PropTypes';
import NextButton from '../buttons/Next';
import PageTitle from '../partials/PageTitle';

import styles from '../../styles/scenes/menu.module.scss';

const propTypes = {
    focusable: PropTypes.bool,
    withOutroButton: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    focusable: false,
    withOutroButton: false,
    className: null,
};

function Menu({ focusable, withOutroButton, className }) {
    const url = useUrlGenerator();
    const intl = useIntl();

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <PageTitle
                title={intl.formatMessage({
                    defaultMessage: 'Navigation | Experience | Dream Voices',
                    description: 'Page title',
                })}
            />
            <div className={styles.inner}>
                {withOutroButton ? (
                    <NextButton
                        href={url('outro')}
                        className={styles.outroButton}
                        focusable={focusable}
                    >
                        <FormattedMessage
                            defaultMessage="Listen to the conclusion"
                            description="Button label"
                        />
                    </NextButton>
                ) : null}
            </div>
        </div>
    );
}

Menu.propTypes = propTypes;
Menu.defaultProps = defaultProps;

export default Menu;

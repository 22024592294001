/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { captionsOff, captionsOn } from '../icons';

import Button from './Button';
import styles from '../../styles/buttons/captions.module.scss';

const propTypes = {
    withCaptions: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
};

const defaultProps = {
    withCaptions: false,
    children: null,
    className: null,
};

function CaptionsButton({ className, withCaptions, children, ...props }) {
    return (
        <Button
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            {...props}
        >
            {withCaptions ? (
                <img src={captionsOn} alt="" className={styles.icon} aria-hidden />
            ) : (
                <img src={captionsOff} alt="" className={styles.icon} aria-hidden />
            )}
            <span className={styles.children}>{children}</span>
        </Button>
    );
}

CaptionsButton.propTypes = propTypes;
CaptionsButton.defaultProps = defaultProps;

export default CaptionsButton;

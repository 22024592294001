import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import useAudios from '../../hooks/useAudios';

import outroEn from '../../audio/OutroEn.mp3';
import outroFr from '../../audio/OutroFr.mp3';
import BackButton from '../buttons/Back';
import NextButton from '../buttons/Next';
import { useCurrentAudio, usePlaybackContext } from '../context/PlaybackContext';

import styles from '../../styles/scenes/outro.module.scss';

const audioFiles = { en: outroEn, fr: outroFr };

const propTypes = {
    muted: PropTypes.bool,
    focusable: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    muted: false,
    focusable: false,
    className: null,
};

function Intro({ muted, focusable, className }) {
    const url = useUrlGenerator();
    const { locale } = useIntl();
    const { lock, unlock } = usePlaybackContext();

    const audio = useAudios(audioFiles[locale], { loop: false, lock, unlock, muted });
    useCurrentAudio(audio);

    useEffect(() => {
        if (audio === null) {
            return () => {};
        }

        return () => {
            audio.pause();
            audio.seek(0);
        };
    }, [audio]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <span className={styles.text} aria-hidden>
                <FormattedMessage
                    defaultMessage='"Our exploration of these dreamscapes has come to an end. Thank you for your participation!"'
                    description="Outro text"
                />
            </span>
            <div className={styles.buttons}>
                <BackButton
                    href={url('experience')}
                    className={styles.button}
                    focusable={focusable}
                >
                    <FormattedMessage
                        defaultMessage="Restart the experience"
                        description="Button label"
                    />
                </BackButton>
                <NextButton href={url('credits')} className={styles.button} focusable={focusable}>
                    <FormattedMessage
                        defaultMessage="Read the credits"
                        description="Button label"
                    />
                </NextButton>
            </div>
        </div>
    );
}

Intro.propTypes = propTypes;
Intro.defaultProps = defaultProps;

export default Intro;

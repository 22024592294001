import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { v4 } from 'uuid';

import styles from '../../styles/partials/circle-group.module.scss';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: '#45438E',
    className: null,
};

function CircleGroup({ color, className }) {
    const id = useMemo(() => v4(), []);
    // const random = useMemo(() => Math.floor(8 + Math.random() * 15), []);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 500 500"
            aria-hidden
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <TransitionGroup component="g">
                <CSSTransition key={color} classNames={styles} timeout={3000}>
                    <g transform="translate(250, 250)">
                        <defs>
                            <radialGradient id={`RadialGradient${color}-${id}`}>
                                <stop offset="0%" stopColor={color} />
                                <stop offset="100%" stopOpacity="0" stopColor={color} />
                            </radialGradient>
                        </defs>
                        <g>
                            <circle
                                id={`circle_${color.replace('#', '')}1-2-${id}`}
                                data-name="Ellipse 153"
                                cx="150"
                                cy="150"
                                r="150"
                                transform="translate(-150 -150)"
                                // fill={colors[0]}
                                fill={`url(#RadialGradient${color}-${id})`}
                            />
                            {/* <animateTransform
                                xlinkHref={`#circle_${color.replace('#', '')}1-2-${id}`}
                                attributeName="transform"
                                attributeType="XML"
                                type="translate"
                                values="-150 -150; -100 -100; -150 -150"
                                additive="sum"
                                begin="0s"
                                // dur="5s"
                                dur={`${random}s`}
                                fill="freeze"
                                repeatCount="1"
                            /> */}
                        </g>
                        <g>
                            <circle
                                id={`circle_${color.replace('#', '')}2-2-${id}`}
                                data-name="Ellipse 153"
                                cx="150"
                                cy="150"
                                r="150"
                                transform="translate(-120 -100)"
                                // fill={colors[0]}
                                fill={`url(#RadialGradient${color}-${id})`}
                            />

                            {/* <animateTransform
                                xlinkHref={`#circle_${color.replace('#', '')}2-2-${id}`}
                                attributeName="transform"
                                attributeType="XML"
                                type="translate"
                                values="-150 -150; -210 -100; -220 -80; -150 -150"
                                additive="sum"
                                begin="0s"
                                // dur="7s"
                                dur={`${random + 2}s`}
                                fill="freeze"
                                repeatCount="1"
                            /> */}
                        </g>
                        <g>
                            <circle
                                id={`circle_${color.replace('#', '')}3-2-${id}`}
                                data-name="Ellipse 153"
                                cx="150"
                                cy="150"
                                r="150"
                                transform="translate(-80 -185)"
                                // fill={colors[0]}
                                fill={`url(#RadialGradient${color}-${id})`}
                            />

                            {/* <animateTransform
                                xlinkHref={`#circle_${color.replace('#', '')}3-2-${id}`}
                                attributeName="transform"
                                attributeType="XML"
                                type="translate"
                                values="-150 -150; -120 -145; -100 -100; -80 -185; -150 -150 "
                                additive="sum"
                                begin="0s"
                                // dur="13s"
                                dur={`${random + 4}s`}
                                fill="freeze"
                                repeatCount="1"
                            /> */}
                        </g>
                        <g>
                            <circle
                                id={`circle_${color.replace('#', '')}4-2-${id}`}
                                data-name="Ellipse 153"
                                cx="150"
                                cy="150"
                                r="150"
                                transform="translate(-200 -100)"
                                // fill="#f3f3f3"
                                fill={`url(#RadialGradient${color}-${id})`}
                            />

                            {/* <animateTransform
                                xlinkHref={`#circle_${color.replace('#', '')}4-2-${id}`}
                                attributeName="transform"
                                attributeType="XML"
                                type="translate"
                                values="-150 -150; -100 -105; -200 -100; -180 -125; -150 -150 "
                                additive="sum"
                                begin="0s"
                                // dur="15s"
                                dur={`${random + 6}s`}
                                fill="freeze"
                                repeatCount="1"
                            /> */}
                        </g>
                    </g>
                </CSSTransition>
            </TransitionGroup>
        </svg>
    );
}

CircleGroup.propTypes = propTypes;
CircleGroup.defaultProps = defaultProps;

export default React.memo(CircleGroup);

import PropTypes from 'prop-types';

export const story = PropTypes.shape({
    id: PropTypes.string,
    audio: PropTypes.string,
});

export const closedCaptionsMedia = PropTypes.shape({
    type: PropTypes.oneOf(['closed-captions']),
});

export const stories = PropTypes.arrayOf(story);

export const imageSize = PropTypes.shape({
    url: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
});

export const imageSizes = PropTypes.arrayOf(imageSize);

export const image = PropTypes.shape({
    url: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    sizes: imageSizes,
});

export const images = PropTypes.arrayOf(image);

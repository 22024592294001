import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';

import CircleGroup from './CircleGroup';

import styles from '../../styles/partials/circles.module.scss';

const propTypes = {
    stories: AppPropTypes.stories,
    color: PropTypes.string,
    faded: PropTypes.bool,
    scene: PropTypes.string,
    storyAudioId: PropTypes.string,
    activeCharacter: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
};

const defaultProps = {
    stories: [],
    color: '#45438E',
    faded: false,
    scene: null,
    storyAudioId: null,
    activeCharacter: null,
    className: null,
    children: null,
};

function Circles({
    stories,
    color,
    faded,
    scene,
    storyAudioId,
    activeCharacter,
    className,
    children,
}) {
    const [playedIntros, setPlayedIntros] = useState([]);

    const activeColor = useMemo(() => {
        if (scene === 'characters') {
            const activeStory = stories.find((story) => story.id === activeCharacter) || null;
            return activeStory?.color;
        }
        if (
            color !== null &&
            (scene === 'profile' ||
                scene === 'story_text' ||
                scene === 'story_audio' ||
                scene === 'home' ||
                scene === 'menu' ||
                scene === 'instructions' ||
                scene === 'intro' ||
                scene === null)
        ) {
            return color;
        }

        return '#45438E';
    }, [scene, activeCharacter, color]);

    useEffect(() => {
        if (activeCharacter === null) {
            return;
        }
        if (scene !== 'characters') {
            setPlayedIntros([]);
            return;
        }
        setPlayedIntros([...playedIntros, activeCharacter]);
    }, [scene, activeCharacter]);

    const name = useMemo(() => {
        if (activeCharacter !== null) {
            return stories.find((story) => story.id === activeCharacter).name;
        }
        return null;
    }, [activeCharacter]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.faded]: faded,
                    [className]: className !== null,
                },
            ])}
            aria-hidden
        >
            {children}
            {stories.map((story) => (
                <div
                    className={classNames([
                        styles.circle,
                        styles[story.id],
                        {
                            [styles.characters]: scene === 'characters',
                            [styles.charactersActive]:
                                scene === 'characters' && activeCharacter === story.id,
                            [styles.charactersPlayed]:
                                scene === 'characters' && playedIntros.includes(story.id),
                            [styles.menu]: scene === 'menu',
                            [styles.home]: scene === 'home',
                            [styles[storyAudioId]]:
                                storyAudioId !== null &&
                                (scene === 'story_audio' || scene === 'story.text'),
                            [styles.outro]: scene === 'outro',
                        },
                    ])}
                    key={story.id}
                >
                    <CircleGroup color={activeColor} className={styles.circleGroup} aria-hidden />
                </div>
            ))}
            {scene === 'characters' && name !== null ? (
                <span className={styles.name} tabIndex={-1}>
                    <FormattedMessage
                        defaultMessage="I am {name}"
                        description="Character intro text"
                        values={{ name }}
                    />
                </span>
            ) : null}
        </div>
    );
}

Circles.propTypes = propTypes;
Circles.defaultProps = defaultProps;

export default React.memo(Circles);

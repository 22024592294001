import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router';

import { stories } from '../../data';
import Experience from '../partials/Experience';

import styles from '../../styles/pages/experience.module.scss';

const isDebug =
    typeof window !== 'undefined' ? window.location.search.indexOf('debug') !== -1 : false;

const propTypes = {
    scene: PropTypes.string,
    menuOpened: PropTypes.bool,
};

const defaultProps = {
    scene: null,
    menuOpened: false,
};

function ExperiencePage({ menuOpened, scene }) {
    const { participant: participantId = null, story = null } = useParams();

    return (
        <div className={styles.container}>
            <Experience
                stories={stories}
                started={!menuOpened}
                focusable={!menuOpened}
                scene={story !== null ? `${scene}_${story}` : scene}
                story={participantId}
                className={styles.experience}
                debug={isDebug}
            />
        </div>
    );
}

ExperiencePage.propTypes = propTypes;
ExperiencePage.defaultProps = defaultProps;

export default ExperiencePage;

import ann1 from './Anne-Jarry_11279.jpg';
import ann2 from './Anne-Jarry_11514.jpg';
import ann3 from './Anne-Jarry_11566.jpg';
import ann4 from './Anne-Jarry_11606.jpg';
import ann5 from './Anne-Jarry_11718.jpg';
import cha1 from './Chantal-Dumas_001.jpeg';
import cha2 from './Chantal-Dumas_002.jpg';
import fra1 from './Francois-Cote_DSF3126.jpg';
import fra2 from './Francois-Cote_DSF3133.jpg';
import fra3 from './Francois-Cote_DSF3255.jpg';
import fra4 from './Francois-cote_DSF3227.jpg';
import jos1 from './Josue-Coude_3446.jpg';
import jos2 from './Josue-Coude_3502.jpg';
import jos3 from './Josue-Coude_3622.jpg';
import jos4 from './Josue-Coude_3732.jpg';
import mar1 from './Marie-Christine-Ricignuolo_12250.jpg';
import mar2 from './Marie-Christine-Ricignuolo_12287.jpg';
import mar3 from './Marie-Christine-Ricignuolo_12311.jpg';
import mar4 from './Marie-Christine-Ricignuolo_12362.jpg';
import nat5 from './Nathalie-Chartrand_11746.jpg';
import nat1 from './Nathalie-Chartrand_11919.jpg';
import nat2 from './Nathalie-Chartrand_11943.jpg';
import nat3 from './Nathalie-Chartrand_12024.jpg';
import nat4 from './Nathalie-Chartrand_12151.jpg';
import rej1 from './Rejane-Bouge_001.jpeg';
import sel1 from './Selma-Kouidri_12460.jpg';
import sel2 from './Selma-Kouidri_12527.jpg';
import sel3 from './Selma-Kouidri_12633.jpg';
import sel4 from './Selma-Kouidri_12774.jpg';
import she4 from './Sherri-Wallace_12834.jpg';
import she1 from './Sherri-Wallace_12884.jpg';
import she2 from './Sherri-Wallace_12934.jpg';
import she3 from './Sherri-Wallace_13139.jpg';
import ste1 from './Stephane-Frigon_13199.jpg';
import ste2 from './Stephane-Frigon_13225.jpg';
import ste3 from './Stephane-Frigon_13349.jpg';
import ste4 from './Stephane-Frigon_13442.jpg';

export const anne = [
    {
        id: 'ann1',
        imgSrc: ann1,
        alt: {
            fr: 'Anne Jarry pose ses mains sur une carte en relief intitulée La Ville de la Havane.',
            en: 'Anne Jarry places her hands on a relief map entitled The City of Havana.',
        },
    },
    {
        id: 'ann2',
        imgSrc: ann2,
        alt: {
            fr: 'Anne Jarry consulte un article en espagnol grâce à un afficheur braille relié à sa tablette.',
            en: 'Anne Jarry reads an article in Spanish on a Braille display connected to her tablet.',
        },
    },
    {
        id: 'ann3',
        imgSrc: ann3,
        alt: {
            fr: 'Anne Jarry tient une fleur orange entre ses mains.',
            en: 'Anne Jarry holds an orange flower in her hands.',
        },
    },
    {
        id: 'ann4',
        imgSrc: ann4,
        alt: {
            fr: 'Anne Jarry marche dehors sur un sentier en gravier avec sa canne blanche de locomotion. ',
            en: 'Anne Jarry walks outside on a gravel path with her white walking stick.',
        },
    },
    {
        id: 'ann5',
        imgSrc: ann5,
        alt: {
            fr: 'Anne Jarry, debout, tient une canne blanche dans sa main gauche.',
            en: 'Anne Jarry, standing, holds a white cane in her left hand.',
        },
    },
];
export const francois = [
    {
        id: 'fra1',
        imgSrc: fra1,
        alt: {
            fr: 'La main et l’avant-bras de François Côté sont éclairés par la lumière du soleil qui traverse la vitre devant laquelle il se trouve.',
            en: 'François Côté’s hand and forearm are illuminated by the sunlight coming through the glass in front of him.',
        },
    },
    {
        id: 'fra2',
        imgSrc: fra2,
        alt: {
            fr: 'François Côté pose ses mains sur la surface d’une grosse roche disposée sur un socle en bois posé au sol. ',
            en: 'François Côté places his hands on the surface of a large rock placed on a wooden base on the ground.',
        },
    },
    {
        id: 'fra3',
        imgSrc: fra3,
        alt: {
            fr: 'François Côté passe ses doits sur la surface d’une sculpture en os posée sur une table. Il porte une alliance à sa main gauche.',
            en: 'François Côté runs his fingers over the surface of a bone sculpture on a table. He is wearing a wedding ring on his left hand.',
        },
    },
    {
        id: 'fra4',
        imgSrc: fra4,
        alt: {
            fr: 'François Côté est assis à l’intérieur devant une fenêtre et fait face à l’objectif de la caméra qui est ébloui par les rayons du soleil.',
            en: 'François Côté is sitting inside in front of a window and faces the camera lens which is dazzled by the sun’s rays.',
        },
    },
];
export const josue = [
    {
        id: 'jos1',
        imgSrc: jos1,
        alt: {
            fr: 'Josué Coudé et son chien regardent par la fenêtre qui donne sur une rue enneigée et éclairée par un soleil radieux.',
            en: 'Josué Coudé and his dog look out the window onto a snowy street lit by a radiant sun.',
        },
    },
    {
        id: 'jos2',
        imgSrc: jos2,
        alt: {
            fr: 'Josué Coudé passe ses doits sur un afficheur braille permettant également la saisie. ',
            en: 'Josué Coudé passes his fingers on a Braille display that also allows for input.',
        },
    },
    {
        id: 'jos3',
        imgSrc: jos3,
        alt: {
            fr: 'Josué Coudé fait du vélo dans son logement. Une serviette est posée sur son guidon.',
            en: 'Josué Coudé rides his bicycle in his apartment. A towel is placed on his handlebars.',
        },
    },
    {
        id: 'jos4',
        imgSrc: jos4,
        alt: {
            fr: 'Une poignée en métal avec un élastique en caoutchouc enroulé autour. ',
            en: 'A metal handle with a rubber band wrapped around it.',
        },
    },
];
export const mariechristine = [
    {
        id: 'mar1',
        imgSrc: mar1,
        alt: {
            fr: 'Marie-Christine Ricignuolo tient un livre intitulé MA VIE DANS LE NOIR.',
            en: 'Marie-Christine Ricignuolo is holding a book entitled MA VIE DANS LE NOIR.',
        },
    },
    {
        id: 'mar2',
        imgSrc: mar2,
        alt: {
            fr: 'Marie-Christine Ricignuolo est devant un mur avec un cadre dans lequel se trouve une photo en noir et blanc d’une femme donnant le biberon à un bébé.',
            en: 'Marie-Christine Ricignuolo is in front of a wall with a frame in which there is a black and white photo of a woman giving a bottle to a baby.',
        },
    },
    {
        id: 'mar3',
        imgSrc: mar3,
        alt: {
            fr: 'Marie-Christine Ricignuolo se trouve au dessus d’un lit et devant un papier peint sur lequel il y a plein d’étoiles et de planètes du système solaire.',
            en: 'Marie-Christine Ricignuolo is above a bed and in front of a wallpaper on which there are many stars and planets of the solar system.',
        },
    },
    {
        id: 'mar4',
        imgSrc: mar4,
        alt: {
            fr: 'Marie-Christine Ricignuolo fait face à une cuisinière sur lequel se trouve un écriteau où il est inscrit "RÊVE GRAND".',
            en: 'Marie-Christine Ricignuolo is facing a stove on which there is a sign where it is written "DREAM BIG".',
        },
    },
];
export const nathalie = [
    {
        id: 'nat1',
        imgSrc: nat1,
        alt: {
            fr: 'Un chien noir frisé regarde paisiblement l’objectif de la caméra alors que Nathalie Chartrand est assise en train de tricoter en arrière-plan.',
            en: 'A black curly dog looks peacefully at the camera lens as Nathalie Chartrand sits knitting in the background.',
        },
    },
    {
        id: 'nat2',
        imgSrc: nat2,
        alt: {
            fr: 'Nathalie Chartrand pose ses mains sur un collage artistique sur lequel se trouvent un soleil et deux poissons ainsi qu’une inscription en braille.',
            en: 'Nathalie Chartrand places her hands on an artistic collage with a sun and two fish and a Braille inscription.',
        },
    },
    {
        id: 'nat3',
        imgSrc: nat3,
        alt: {
            fr: 'Nathalie Chartrand déploie un drap au-dessus d’un lit de massage sur lequel est posé un coussin. ',
            en: 'Nathalie Chartrand spreads a sheet over a massage bed with a cushion on it.',
        },
    },
    {
        id: 'nat4',
        imgSrc: nat4,
        alt: {
            fr: 'Nathalie Chartrand passe rapidement devant une peinture encadrée et accrochée au mur. Sur la peinture, on distingue deux hommes dans une barque sur l’eau.',
            en: 'Nathalie Chartrand passes quickly in front of a framed painting hanging on the wall. The painting shows two men in a boat on the water.',
        },
    },
    {
        id: 'nat5',
        imgSrc: nat5,
        alt: {
            fr: 'Une médaille de bronze et une médaille d’or de jeux paralympiques.',
            en: 'A bronze medal and a gold medal from the Paralympic Games.',
        },
    },
];
export const selma = [
    {
        imgSrc: sel1,
        alt: {
            fr: 'Selma Kouidri pose ses mains sur un tajine posé sur une table.',
            en: 'Selma Kouidri places her hands on a tagine on a table.',
        },
    },
    {
        imgSrc: sel2,
        alt: {
            fr: 'Un livre posé avec des jeux de société sur une table contre un mur.',
            en: 'A book sits with board games on a table against a wall.',
        },
    },
    {
        imgSrc: sel3,
        alt: {
            fr: 'Selma Kouidri se trouve debout devant un parc avec des jeux pour enfants.',
            en: 'Selma Kouidri stands in front of a park with children’s games.',
        },
    },
    {
        imgSrc: sel4,
        alt: {
            fr: 'Selma Kouidri marche avec sa canne blanche de locomotion à travers un stationnement entre deux immeubles en briques.',
            en: 'Selma Kouidri walks with her white walking stick across a parking lot between two brick buildings.',
        },
    },
];
export const sherri = [
    {
        imgSrc: she1,
        alt: {
            fr: 'Sherri Wallace fait tourner un cerceau d’exercice autour d’elle en se tenant debout dans un salon.',
            en: 'Sherri Wallace spins an exercise hoop around while standing in a living room.',
        },
    },
    {
        imgSrc: she2,
        alt: {
            fr: 'Sherri Wallace a le bras tendu à travers un cerceau d’exercice.',
            en: 'Sherri Wallace has her arm extended through an exercise hoop.',
        },
    },
    {
        imgSrc: she3,
        alt: {
            fr: 'Sherri Wallace marche dehors devant une station de métro. Elle tient une canne blanche de locomotion dans la main droite.',
            en: 'Sherri Wallace walks outside a subway station. She holds a white walking stick in her right hand.',
        },
    },
    {
        imgSrc: she4,
        alt: {
            fr: 'Sherri Wallace déplie un livret dans lequel se trouvent des rectangles en tissus de différentes couleurs.',
            en: 'Sherri Wallace unfolds a booklet with colored fabric rectangles.',
        },
    },
];
export const stephane = [
    {
        imgSrc: ste1,
        alt: {
            fr: 'Stéphane Frigon ouvre le store en bois d’une fenêtre donnant sur la rue au rez-de-chaussée.',
            en: 'Stéphane Frigon opens the wooden blind of a window overlooking the street on the first floor.',
        },
    },
    {
        imgSrc: ste2,
        alt: {
            fr: 'Stéphane Frigon joue de la guitare acoustique.',
            en: 'Stéphane Frigon plays an acoustic guitar.',
        },
    },
    {
        imgSrc: ste3,
        alt: {
            fr: 'Stéphane Frigon sort des livres d’une grande caisse en étant assis par terre en tailleur.',
            en: 'Stéphane Frigon takes books out of a large box while sitting cross-legged on the floor.',
        },
    },
    {
        imgSrc: ste4,
        alt: {
            fr: 'Trois cannes blanches éclairées pas la lumière du soleil sont posées contre une fenêtre donnant sur une rue.',
            en: 'Three white canes illuminated by sunlight are placed against a window overlooking a street.',
        },
    },
];
export const chantal = [cha1, cha2];
export const rejane = [rej1];

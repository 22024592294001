/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { soundOff, soundOn } from '../icons';

import Button from './Button';
import styles from '../../styles/buttons/mute.module.scss';

const propTypes = {
    muted: PropTypes.bool,
    children: PropTypes.node,

    className: PropTypes.string,
};

const defaultProps = {
    muted: false,
    children: null,

    className: null,
};

function MuteButton({ className, muted, children, ...props }) {
    return (
        <Button
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            {...props}
        >
            <div className={styles.iconContainer}>
                {muted ? (
                    <img src={soundOff} alt="" className={styles.icon} aria-hidden />
                ) : (
                    <img src={soundOn} alt="" className={styles.icon} aria-hidden />
                )}
            </div>
            <div className={styles.children}>{children}</div>
        </Button>
    );
}

MuteButton.propTypes = propTypes;
MuteButton.defaultProps = defaultProps;

export default MuteButton;

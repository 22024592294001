import { useRoutes } from '@folklore/routes';
import PropTypes from 'prop-types';
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import MainLayout from './layouts/Main';
import AboutPage from './pages/About';
import CreditsPage from './pages/Credits';
import ExperiencePage from './pages/Experience';
import HomePage from './pages/Home';
import InstructionsPage from './pages/Instructions';

import '../styles/main.scss';

const propTypes = {
    locales: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
    locales: [],
};

function App({ locales }) {
    const routes = useRoutes();
    return (
        <Routes>
            <Route
                path={routes.home}
                element={
                    <MainLayout fullscreen currentRoute="home">
                        <HomePage />
                    </MainLayout>
                }
            />
            <Route
                path={routes.intro}
                element={
                    <MainLayout fullscreen currentRoute="intro">
                        <ExperiencePage scene="intro" />
                    </MainLayout>
                }
            />
            <Route
                path={routes.participants}
                element={
                    <MainLayout currentRoute="participants">
                        <ExperiencePage scene="menu" />
                    </MainLayout>
                }
            />
            <Route
                path={routes.participant}
                element={
                    <MainLayout currentRoute="participant">
                        <ExperiencePage scene="profile" />
                    </MainLayout>
                }
            />
            <Route
                path={routes.story}
                element={
                    <MainLayout currentRoute="story">
                        <ExperiencePage scene="story" />
                    </MainLayout>
                }
            />
            <Route
                path={routes.outro}
                element={
                    <MainLayout fullscreen currentRoute="outro">
                        <ExperiencePage scene="outro" />
                    </MainLayout>
                }
            />
            <Route
                path={routes.about}
                element={
                    <MainLayout currentRoute="about">
                        <AboutPage />
                    </MainLayout>
                }
            />
            <Route
                path={routes.instructions}
                element={
                    <MainLayout currentRoute="instructions">
                        <InstructionsPage />
                    </MainLayout>
                }
            />
            <Route
                path={routes.credits}
                element={
                    <MainLayout currentRoute="credits">
                        <CreditsPage />
                    </MainLayout>
                }
            />
            <Route path="/" element={<Navigate to="/fr" />} />
        </Routes>
    );
}

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;

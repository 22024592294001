/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

import { play, pause } from '../icons';
import Button from './Button';

import styles from '../../styles/buttons/play-pause.module.scss';

const propTypes = {
    paused: PropTypes.bool,
    onClick: PropTypes.func,
    iconColor: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    paused: false,
    onClick: null,
    iconColor: null,
    className: null,
};

function PlayPauseButton({ paused, onClick, iconColor, className, ...props }) {
    const intl = useIntl();
    return (
        <Button
            aria-label={
                paused
                    ? intl.formatMessage({
                          defaultMessage: 'Play audio',
                          description: 'Button label',
                      })
                    : intl.formatMessage({
                          defaultMessage: 'Pause audio',
                          description: 'Button label',
                      })
            }
            onClick={onClick}
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            {...props}
        >
            {paused ? (
                <img src={play} alt="" className={styles.icon} aria-hidden />
            ) : (
                <img src={pause} alt="" className={styles.icon} aria-hidden />
            )}
        </Button>
    );
}

PlayPauseButton.propTypes = propTypes;
PlayPauseButton.defaultProps = defaultProps;

export default PlayPauseButton;

/* eslint-disable no-underscore-dangle */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import useAudioCurrentTime from '../../hooks/useAudioCurrentTime';
import useAudios from '../../hooks/useAudios';
import * as AppPropTypes from '../../lib/PropTypes';

import { useCurrentAudio, usePlaybackContext } from '../context/PlaybackContext';
import ClosedCaptions from '../partials/ClosedCaptions';
import PageTitle from '../partials/PageTitle';
import Reader from '../partials/Reader';

import styles from '../../styles/scenes/story.module.scss';

const propTypes = {
    sceneId: PropTypes.string,
    story: AppPropTypes.story.isRequired,
    muted: PropTypes.bool,
    audioId: PropTypes.string,
    withReader: PropTypes.bool,
    setWithReader: PropTypes.func,
    withClosedCaptions: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    sceneId: null,
    muted: false,
    audioId: 'text',
    withReader: false,
    setWithReader: null,
    withClosedCaptions: false,
    className: null,
};

function Story({
    sceneId,
    story,
    muted,
    audioId,
    withReader,
    setWithReader,
    withClosedCaptions,
    className,
}) {
    const intl = useIntl();
    const { locale } = intl || {};
    const { lock, unlock } = usePlaybackContext();

    const { name = null, text = null, subtitles = {} } = story || null;
    const { transcription: audioText = {} } = subtitles || {};

    const [playedAudios, setPlayedAudios] = useState([]);

    // AUDIO
    const audiosMap = useMemo(
        () => ({
            audio: story.audio !== null ? story.audio : null,
            text: story.audioText[locale] !== null ? story.audioText[locale] : null,
        }),
        [name],
    );

    const audios = useAudios(audiosMap, { loop: false, lock, unlock, muted });
    const audio = audios[audioId] || null;
    const currentTime = useAudioCurrentTime(audio);
    useCurrentAudio(audio);

    useEffect(() => {
        if (audio === null) {
            return () => {};
        }

        return () => {
            audio.pause();
            audio.seek(0);
        };
    }, [audio]);

    useEffect(() => {
        if (audio === null) {
            return () => {};
        }

        function onEnded() {
            if (audioId === 'text') {
                setPlayedAudios([...playedAudios, 'text']);
            } else if (audioId === 'audio') {
                setPlayedAudios([...playedAudios, 'audio']);
            }
        }

        audio.on('end', onEnded);
        return () => {
            audio.off('end', onEnded);
        };
    }, [audio, audioId, setPlayedAudios, playedAudios]);

    const hideReader = useCallback(() => {
        setWithReader(false);
    }, [withReader, setWithReader]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <PageTitle
                title={
                    audioId === 'text'
                        ? intl.formatMessage(
                              {
                                  defaultMessage: "{name}'s Portrait | Experience | Dream Voices",
                                  description: 'Page title',
                              },
                              { name: story.name !== null ? story.name : 'Participant' },
                          )
                        : intl.formatMessage(
                              {
                                  defaultMessage:
                                      "{name}'s Dream Audio | Experience | Dream Voices",
                                  description: 'Page title',
                              },
                              { name: story.name !== null ? story.name : 'Participant' },
                          )
                }
            />
            {withReader === false ? (
                <div
                    className={classNames([
                        styles.inner,
                        {
                            [className]: className !== null,
                            [styles.reader]: withReader,
                        },
                    ])}
                >
                    <h2 className={styles.title}>
                        {audioId === 'text' ? (
                            <FormattedMessage
                                defaultMessage="{name}'s Portrait"
                                description="Button label"
                                values={{ name: <span className={styles.notItalic}>{name}</span> }}
                            />
                        ) : (
                            <FormattedMessage
                                defaultMessage="{name}'s Dream Audio"
                                description="Button label"
                                values={{ name: <span className={styles.notItalic}>{name}</span> }}
                            />
                        )}
                    </h2>
                    <h3 className={styles.subtitle}>
                        {audioId === 'text' ? (
                            <FormattedMessage
                                defaultMessage="by Réjane Bougé"
                                description="Button label"
                            />
                        ) : (
                            <FormattedMessage
                                defaultMessage="by Chantal Dumas"
                                description="Button label"
                            />
                        )}
                    </h3>

                    {audioId === 'audio' && withClosedCaptions ? (
                        <ClosedCaptions
                            media={story.subtitles[locale]}
                            currentTime={currentTime}
                            className={styles.closedCaptions}
                            timeOffset={story.id === 'francois' ? 3.5 : null}
                            aria-hidden
                        />
                    ) : null}
                </div>
            ) : null}
            {withReader ? (
                <Reader
                    title={name}
                    body={sceneId === 'story_audio' ? audioText[locale] : text[locale]}
                    onClickBack={hideReader}
                />
            ) : (
                false
            )}
        </div>
    );
}

Story.propTypes = propTypes;
Story.defaultProps = defaultProps;

export default Story;

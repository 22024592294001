/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import Button from './Button';
import { burger, menuExit } from '../icons';

import styles from '../../styles/buttons/menu.module.scss';

const propTypes = {
    opened: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    opened: false,
    className: null,
};

function MenuButton({ opened, className, ...props }) {
    const intl = useIntl();
    return (
        <Button
            aria-label={intl.formatMessage({
                defaultMessage: 'Menu',
                description: 'Button label',
            })}
            aria-expanded={opened}
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            {...props}
        >
            {opened ? (
                <img src={menuExit} alt="" className={styles.icon} aria-hidden />
            ) : (
                <img src={burger} alt="" className={styles.icon} aria-hidden />
            )}
        </Button>
    );
}

MenuButton.propTypes = propTypes;
MenuButton.defaultProps = defaultProps;

export default MenuButton;

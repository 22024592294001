import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import useAudioCurrentTime from '../../hooks/useAudioCurrentTime';
import useAudios from '../../hooks/useAudios';

import introEn from '../../audio/IntroEn.mp3';
import introFr from '../../audio/IntroFr.mp3';
import { intro as introEnSubs } from '../../subtitles/en';
import { intro as introFrSubs } from '../../subtitles/fr';
import NextButton from '../buttons/Next';
import { useCurrentAudio, usePlaybackContext } from '../context/PlaybackContext';
import ClosedCaptions from '../partials/ClosedCaptions';
import PageTitle from '../partials/PageTitle';

import styles from '../../styles/scenes/intro.module.scss';

const audioFiles = { en: introEn, fr: introFr };

const propTypes = {
    focusable: PropTypes.bool,
    paused: PropTypes.bool,
    muted: PropTypes.bool,
    withClosedCaptions: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    focusable: false,
    paused: false,
    muted: false,
    withClosedCaptions: true,
    className: null,
};

function Intro({ focusable, paused, muted, withClosedCaptions, className }) {
    const { locale } = useIntl();
    const intl = useIntl();
    const { lock, unlock } = usePlaybackContext();

    const audio = useAudios(audioFiles[locale], { loop: false, lock, unlock, muted });
    const currentTime = useAudioCurrentTime(audio);
    useCurrentAudio(audio);

    useEffect(() => {
        if (audio === null) {
            return () => {};
        }

        return () => {
            audio.pause();
            audio.seek(0);
        };
    }, [audio]);

    const url = useUrlGenerator();

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <PageTitle
                title={intl.formatMessage({
                    defaultMessage: 'Introduction | Experience | Dream Voices',
                    description: 'Page title',
                })}
            />
            {withClosedCaptions ? (
                <ClosedCaptions
                    media={locale === 'fr' ? introFrSubs : introEnSubs}
                    currentTime={currentTime}
                    className={styles.closedCaptions}
                    timeOffset={locale === 'fr' ? -0.5 : null}
                    aria-hidden
                />
            ) : null}
            <NextButton href={url('participants')} className={styles.button} focusable={focusable}>
                <FormattedMessage defaultMessage="Go to participants" description="Button label" />
            </NextButton>
        </div>
    );
}

Intro.propTypes = propTypes;
Intro.defaultProps = defaultProps;

export default Intro;

import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import shuffle from 'lodash/shuffle';

export const pascalCase = (str) =>
    str !== null
        ? str
              .replace(/[^a-z0-9]+/gi, ' ')
              .replace(/(\w)(\w*)/g, (g0, g1, g2) => `${g1.toUpperCase()}${g2}`)
              .replace(/\s+/gi, '')
        : null;

export const getComponentFromName = (components, name, defaultComponentName = null) => {
    const defaultComponent =
        (isString(defaultComponentName)
            ? components[pascalCase(defaultComponentName)]
            : defaultComponentName) || null;
    if (name === null) {
        return defaultComponent;
    }
    const componentName = pascalCase(name);
    return components[componentName] || defaultComponent;
};

export function formatTime(secondsTotal) {
    const minutes = Math.floor(secondsTotal / 60);
    const seconds = Math.round(secondsTotal - minutes * 60);
    return [
        minutes < 10 ? `0${minutes}` : `${minutes}`,
        seconds < 10 ? `0${seconds}` : `${seconds}`,
    ].join(':');
}

export const getSecondsFromTime = function getSecondsFromTime(time) {
    const t = time.split(':');

    try {
        let s = t[2].split(',');

        if (s.length === 1) {
            s = t[2].split('.');
        }

        return (
            parseFloat(t[0], 10) * 3600 +
            parseFloat(t[1], 10) * 60 +
            parseFloat(s[0], 10) +
            parseFloat(s[1], 10) / 1000
        );
    } catch (e) {
        return 0;
    }
};

export const getImageSize = (image, size) =>
    image !== null && image !== undefined
        ? (image.sizes || []).find((it) => it.id === size) || image
        : null;

export const getImageAtSize = (image, size, webp = false) => {
    if (image === null) {
        return null;
    }
    const { sizes: imagesSizes = {}, ...originalImage } = image;
    return (
        [...(isArray(size) ? size : [size]), 'original']
            .reduce(
                (acc, currentSize) =>
                    webp ? [...acc, `${currentSize}_webp`, currentSize] : [...acc, currentSize],
                [],
            )
            .reduce(
                (selectedImage, currentSize) => selectedImage || imagesSizes[currentSize] || null,
                null,
            ) || originalImage
    );
};

export const getImageSizeUrl = (image, size) => {
    const imageAtSize = getImageSize(image, size);
    return imageAtSize !== null ? imageAtSize.url || null : null;
};

// eslint-disable-next-line import/prefer-default-export
export function distance(x1, y1, x2, y2) {
    const a = x2 - x1;
    const b = y2 - y1;

    return Math.sqrt(a * a + b * b);
}

export function getBodiesFromStories(
    stories,
    {
        shipSize = 0.01,
        bodySize = 0.02,
        worldWidth = 800,
        worldHeight = 600,
        origin = null,
        minExtraDistance = 0.1,
    } = {},
) {
    function getShape(x, y, size) {
        return {
            x: x * worldWidth,
            y: x * worldHeight,
            radius: size * worldWidth,
        };
    }

    const usedShapes = origin !== null ? [getShape(origin.x, origin.y, shipSize)] : [];
    function shapeOverlap(shape1, shape2) {
        const shapeDistance = distance(shape1.x, shape1.y, shape2.x, shape2.y);
        // return false;
        return shapeDistance < shape1.radius + shape2.radius + minExtraDistance * worldWidth;
    }
    function isPositionOverlap(shape) {
        return usedShapes.reduce(
            (overlap, usedShape) => overlap || shapeOverlap(usedShape, shape),
            false,
        );
    }
    function findUnusedPosition(size) {
        let x;
        let y;
        let shape;
        let increments = 0;
        do {
            const opposite = Math.random() < 0.5;
            x = size + Math.random() * (1 - 2 * size);
            y = size + Math.random() * (1 - 2 * size);
            shape = getShape(opposite ? 1 - x : x, opposite ? 1 - y : y, size);
            increments += 1;
            // console.log(increments, isPositionOverlap(shape), usedShapes.length)
        } while (isPositionOverlap(shape) && increments <= 10);
        return {
            x,
            y,
            shape,
        };
    }

    const colPadding = worldWidth * bodySize * 2;
    const rowPadding = worldHeight * bodySize * 2;
    const realWorldWidth = worldWidth - 2 * colPadding;
    const realWorldHeight = worldHeight - 2 * rowPadding;
    const realBodySize = bodySize * worldWidth * 4;
    const cols = Math.floor(realWorldWidth / realBodySize);
    const rows = Math.floor(realWorldHeight / realBodySize);
    let cells = [...Array(rows).keys()].reduce(
        (allCells, row) => [
            ...allCells,
            ...[...Array(cols).keys()].map((col) => [col / cols, row / rows]),
        ],
        [],
    );

    cells = shuffle(cells);

    const count = stories.length;
    const minStartRadius = 0.1;
    const maxStartRadis = 0.2;
    const minRadius = 0.3;
    const maxRadis = 0.5;

    return stories.map((story, index) => {
        // cells = shuffle(cells);
        // const cell = cells.shift();
        // const { x, y, shape } = findUnusedPosition(bodySize);
        // usedShapes.push(shape);
        // console.log(story.id, cell);

        const progress = index / count;
        const angle = (progress * 360 * Math.PI) / 180;
        const startRadius = minStartRadius + (maxStartRadis - minStartRadius) * Math.random();
        const finalRadius = minRadius + (maxRadis - minRadius) * Math.random();
        const startX = startRadius * Math.sin(angle) + 0.5;
        const startY = startRadius * Math.cos(angle) + 0.5;
        const finalX = finalRadius * Math.sin(angle) + 0.5;
        const finalY = finalRadius * Math.cos(angle) + 0.5;

        // console.log({
        //     index,
        //     progress,
        //     x: startX,
        //     y: startY,
        //     finalX,
        //     finalY,
        //     startRadius,
        //     finalRadius,
        // })

        return {
            ...story,
            x: startX,
            y: startY,
            finalX,
            finalY,
            // x: cell[0] + bodySize * 2 - bodySize + Math.random() * (bodySize * 2),
            // y: cell[1] + bodySize * 2 - bodySize + Math.random() * (bodySize * 2),
        };
    });
}

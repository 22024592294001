/* eslint-disable react/no-danger */
import classNames from 'classnames';
import { Howl } from 'howler';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import useAudioCurrentTime from '../../hooks/useAudioCurrentTime';
import useAudioDuration from '../../hooks/useAudioDuration';
import { formatTime } from '../../lib/utils';

import CaptionsButton from '../buttons/Captions';
import MuteButton from '../buttons/Mute';
import PlayPauseButton from '../buttons/PlayPause';
import ReaderButton from '../buttons/Reader';

import styles from '../../styles/partials/controls.module.scss';

const propTypes = {
    audio: PropTypes.instanceOf(Howl),
    paused: PropTypes.bool,
    muted: PropTypes.bool,
    volume: PropTypes.number,
    scene: PropTypes.shape({
        id: PropTypes.string,
    }),
    focusable: PropTypes.bool,
    onClickPause: PropTypes.func,
    onClickPlay: PropTypes.func,
    onClickMute: PropTypes.func,
    onClickCaptions: PropTypes.func,
    // storyAudioId: PropTypes.string,
    withReaderButton: PropTypes.bool,
    withReader: PropTypes.func,
    withClosedCaptions: PropTypes.bool,
    onVolumeChange: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    audio: null,
    paused: false,
    muted: false,
    volume: 1,
    scene: 'instructions',
    focusable: true,
    onClickPause: null,
    onClickPlay: null,
    onClickMute: null,
    onClickCaptions: null,
    // storyAudioId: 'text',
    withReaderButton: false,
    withReader: null,
    withClosedCaptions: false,
    onVolumeChange: null,
    className: null,
};

function Controls({
    audio,
    paused,
    muted,
    volume,
    scene,
    focusable,
    onClickPause,
    onClickPlay,
    onClickMute,
    onClickCaptions,
    // storyAudioId,
    withReaderButton,
    withReader,
    withClosedCaptions,
    onVolumeChange,
    className,
}) {
    const intl = useIntl();

    // const subsDisabled = storyAudioId !== 'audio' || scene.id !== 'intro';
    const withSubs = scene.id === 'intro' || scene.id === 'story_audio';

    const duration = useAudioDuration(audio);
    const currentTime = useAudioCurrentTime(audio);
    const progress = duration > 0 ? currentTime / duration : 0;
    const [seekProgress, setSeekProgress] = useState(progress);
    const [lastSeekTime, setLastSeekTime] = useState(null);
    const finalProgress = lastSeekTime !== null && ((new Date()).getTime() - lastSeekTime) < 1000 ? seekProgress : progress;

    const onProgressChange = useCallback(
        (e) => {
            const newProgress = parseInt(e.target.value, 10) / 100;
            setSeekProgress(newProgress);
            setLastSeekTime(new Date().getTime());
            audio.seek(newProgress * duration);
        },
        [audio, duration],
    );

    const onVolumeInputChange = useCallback(
        (e) => {
            const newVolume = parseInt(e.target.value, 10) / 100;
            if (onVolumeChange !== null) {
                onVolumeChange(newVolume);
            }
        },
        [onVolumeChange],
    );

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                    [styles.noProgressBar]: audio === null,
                },
            ])}
        >
            {audio !== null ? (
                <nav className={classNames([styles.navigation, styles.audio])} id="audioControls">
                    <ul className={styles.controls}>
                        <li>
                            <PlayPauseButton
                                paused={paused}
                                onClick={paused ? onClickPlay : onClickPause}
                                className={styles.iconButton}
                                focusable={focusable}
                            />
                        </li>

                        <li className={styles.timeline}>
                            <input
                                type="range"
                                value={finalProgress * 100}
                                min={0}
                                max={100}
                                tabIndex={!focusable ? -1 : null}
                                onChange={onProgressChange}
                                aria-label={intl.formatMessage({
                                    defaultMessage: 'Audio progress bar',
                                    description: 'Button label',
                                })}
                                className={styles.progress}
                            />
                        </li>

                        <li className={styles.time}>
                            {formatTime(currentTime)
                                .split('')
                                .map((it) => (
                                    <span>{it}</span>
                                ))}{' '}
                            /{' '}
                            {formatTime(duration)
                                .split('')
                                .map((it) => (
                                    <span>{it}</span>
                                ))}
                        </li>

                        <li>
                            <MuteButton
                                className={styles.item}
                                muted={muted}
                                focusable={focusable}
                                onClick={onClickMute}
                                aria-label={
                                    muted
                                        ? intl.formatMessage({
                                              defaultMessage: 'Unmute sound',
                                              description: 'Button label',
                                          })
                                        : intl.formatMessage({
                                              defaultMessage: 'Mute sound',
                                              description: 'Button label',
                                          })
                                }
                            />
                        </li>

                        <li className={styles.volume}>
                            <input
                                type="range"
                                value={(volume || 0) * 100}
                                min={0}
                                max={100}
                                tabIndex={!focusable ? -1 : null}
                                onChange={onVolumeInputChange}
                                aria-label={intl.formatMessage({
                                    defaultMessage: 'Volume progress bar',
                                    description: 'Button label',
                                })}
                                className={styles.progress}
                            />
                        </li>
                    </ul>
                </nav>
            ) : null}

            <nav className={classNames([styles.navigation, styles.options])}>
                <ul className={styles.controls}>
                    {withReaderButton ? (
                        <li>
                            <ReaderButton
                                className={styles.item}
                                focusable={focusable}
                                onClick={withReader}
                            >
                                <FormattedMessage
                                    defaultMessage="Show text version"
                                    description="Button label"
                                />
                            </ReaderButton>
                        </li>
                    ) : null}

                    <li>
                        {withSubs ? (
                            <CaptionsButton
                                withCaptions={withClosedCaptions}
                                disabled={!withSubs}
                                focusable={focusable}
                                onClick={onClickCaptions}
                                className={styles.item}
                            >
                                {withClosedCaptions ? (
                                    <FormattedMessage
                                        defaultMessage="Subtitles On"
                                        description="Button label"
                                    />
                                ) : (
                                    <FormattedMessage
                                        defaultMessage="Subtitles Off"
                                        description="Button label"
                                    />
                                )}
                            </CaptionsButton>
                        ) : null}
                        {!withSubs ? (
                            <CaptionsButton
                                withCaptions={withClosedCaptions}
                                disabled
                                focusable={focusable}
                                onClick={onClickCaptions}
                                className={styles.item}
                            >
                                <FormattedMessage
                                    defaultMessage="Subtitles are unavailable"
                                    description="Button label"
                                />
                            </CaptionsButton>
                        ) : null}
                    </li>
                </ul>
            </nav>
        </div>
    );
}

Controls.propTypes = propTypes;
Controls.defaultProps = defaultProps;

export default Controls;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from './Button';
import styles from '../../styles/buttons/menu-item.module.scss';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function MenuItemButton({ className, ...props }) {
    return (
        <Button
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            {...props}
        />
    );
}

MenuItemButton.propTypes = propTypes;
MenuItemButton.defaultProps = defaultProps;

export default MenuItemButton;

/* eslint-disable no-param-reassign */
import { useEffect } from 'react';

function useAudioState(audio = null, options = {}) {
    const { paused = false, muted = false, loop = false, storyId = null } = options || {};

    useEffect(() => {
        if (audio === null) {
            return;
        }

        if (paused && audio.playing()) {
            audio.pause();
        } else if (!paused && !audio.playing()) {
            try {
                audio.play();
                // console.log('audio play');
            } catch (e) {
                // console.log('audio play error');
            }
        }
    }, [audio, paused, muted]);

    useEffect(() => {
        if (audio === null) {
            return;
        }
        audio.mute(muted);
        // console.log('auto mute', muted, audio._src);
    }, [audio, muted, paused, storyId]);

    useEffect(() => {
        if (audio === null) {
            return;
        }
        if (loop) {
            audio.loop(true);
        }
    }, [audio, loop]);
}

export default useAudioState;

/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

// import useClickAudio from '../../hooks/useClickAudio';
import styles from '../../styles/buttons/button.module.scss';

const propTypes = {
    type: PropTypes.string,
    href: PropTypes.string,
    external: PropTypes.bool,
    children: PropTypes.node,
    focusable: PropTypes.bool,
    tabIndex: PropTypes.number,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    type: 'button',
    href: null,
    external: false,
    children: null,
    focusable: true,
    tabIndex: null,
    onClick: null,
    className: null,
};

function Button({
    href,
    external,
    type,
    focusable,
    tabIndex,
    onClick: customOnClick,
    className,
    children,
    ...props
}) {
    const isLink = href !== null;
    let ButtonComponent = 'button';
    if (isLink) {
        ButtonComponent = external ? 'a' : Link;
    }
    const onClick = useCallback(() => {
        if (customOnClick !== null) {
            customOnClick();
        }
    }, [customOnClick]);

    return (
        <ButtonComponent
            type={!isLink ? type : null}
            href={isLink && external ? href : null}
            to={isLink && !external ? href : null}
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            tabIndex={!focusable ? -1 : tabIndex}
            onClick={onClick}
            {...props}
        >
            {children}
        </ButtonComponent>
    );
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;

/* eslint-disable import/prefer-default-export */
import annEn from './TexteAnneEn.mp3';
import annFr from './TexteAnneFr.mp3';
import fraEn from './TexteFrancoisEn.mp3';
import fraFr from './TexteFrancoisFr.mp3';
import josEn from './TexteJosueEn.mp3';
import josFr from './TexteJosueFr.mp3';
import marEn from './TexteMarie-ChristineEn.mp3';
import marFr from './TexteMarie-ChristineFr.mp3';
import natEn from './TexteNathalieEn.mp3';
import natFr from './TexteNathalieFr.mp3';
import selEn from './TexteSelmaEn.mp3';
import selFr from './TexteSelmaFr.mp3';
import sheEn from './TexteSherriEn.mp3';
import sheFr from './TexteSherriFr.mp3';
import steEn from './TexteStephaneEn.mp3';
import steFr from './TexteStephaneFr.mp3';

export const anne = { en: annEn, fr: annFr };
export const francois = { en: fraEn, fr: fraFr };
export const josue = { en: josEn, fr: josFr };
export const mariechristine = { en: marEn, fr: marFr };
export const nathalie = { en: natEn, fr: natFr };
export const selma = { en: selEn, fr: selFr };
export const sherri = { en: sheEn, fr: sheFr };
export const stephane = { en: steEn, fr: steFr };

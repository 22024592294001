/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { galleryArrow } from '../icons';
import Button from './Button';

import styles from '../../styles/buttons/arrow.module.scss';

const propTypes = {
    onClick: PropTypes.func,
    iconColor: PropTypes.string,

    className: PropTypes.string,
};

const defaultProps = {
    onClick: null,
    iconColor: null,
    className: null,
};

const ArrowButton = ({ onClick, iconColor, className, ...props }) => (
    <Button
        onClick={onClick}
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
        {...props}
    >
        <img src={galleryArrow} alt="" className={styles.icon} aria-hidden />
    </Button>
);

ArrowButton.propTypes = propTypes;
ArrowButton.defaultProps = defaultProps;

export default ArrowButton;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useMemo, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import useAudioState from '../../hooks/useAudioState';
import useAudios from '../../hooks/useAudios';
import useSceneUrlGenerator from '../../hooks/useSceneUrlGenerator';
import * as AppPropTypes from '../../lib/PropTypes';

import NextButton from '../buttons/Next';
import { usePlaybackContext } from '../context/PlaybackContext';
import PageTitle from '../partials/PageTitle';

import styles from '../../styles/scenes/characters.module.scss';

const propTypes = {
    stories: AppPropTypes.stories,
    paused: PropTypes.bool,
    muted: PropTypes.bool,
    focusable: PropTypes.bool,
    setActiveCharacter: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    stories: [],
    paused: false,
    muted: false,
    focusable: false,
    setActiveCharacter: null,
    className: null,
};

function Characters({ stories, paused, muted, focusable, setActiveCharacter, className }) {
    const { locale } = useIntl();
    const intl = useIntl();
    const navigate = useNavigate();
    const sceneUrl = useSceneUrlGenerator();
    const { lock, unlock } = usePlaybackContext();

    const [characterIds, setCharacterIds] = useState(stories.map(({ id }) => id));

    const audiosMap = useMemo(
        () =>
            stories.reduce(
                (map, story) => ({
                    ...map,
                    [story.id]: story.audioEnter[locale] || null,
                }),
                {},
            ),
        [locale],
    );

    const audios = useAudios(audiosMap, { loop: false, lock, unlock, muted });
    const [audioId = null] = characterIds || [];
    const audio = audioId !== null ? audios[audioId] : null;

    useEffect(() => {
        if (characterIds.length === 0) {
            return;
        }

        let timeout = null;
        timeout = setTimeout(() => {
            const newCharactersIds = characterIds.slice(1);
            setCharacterIds(newCharactersIds);
            if (newCharactersIds.length === 0) {
                navigate(sceneUrl('menu'));
            }
        }, 3000);

        setActiveCharacter(characterIds[0]);

        // eslint-disable-next-line consistent-return
        return () => {
            if (timeout !== null) {
                clearTimeout();
            }
        };
    }, [audios, characterIds, navigate, sceneUrl]);

    useAudioState(audio, {
        paused,
        muted,
    });

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <PageTitle
                title={intl.formatMessage({
                    defaultMessage: 'Introduction of the Participants | Experience | Dream Voices',
                    description: 'Page title',
                })}
            />
            <NextButton href={sceneUrl('menu')} focusable={focusable}>
                <FormattedMessage defaultMessage="Skip Introduction" description="Button label" />
            </NextButton>
        </div>
    );
}

Characters.propTypes = propTypes;
Characters.defaultProps = defaultProps;

export default Characters;

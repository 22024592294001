/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { externalLink } from '../icons';

import Button from './Button';
import styles from '../../styles/buttons/external-link.module.scss';

const propTypes = {
    children: PropTypes.node,
    screenReaderBefore: PropTypes.string,
    screenReaderAfter: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    children: null,
    screenReaderBefore: null,
    screenReaderAfter: null,
    className: null,
};

function ExternalLinkButton({
    className,
    screenReaderBefore,
    screenReaderAfter,
    children,
    ...props
}) {
    return (
        <Button
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            {...props}
        >
            <span className={styles.shadow} />
            <div className={styles.children}>
                {screenReaderBefore ? (
                    <span className={styles.screenReaderOnly}>{screenReaderBefore}</span>
                ) : null}
                {children}
                {screenReaderAfter ? (
                    <span className={styles.screenReaderOnly}>{screenReaderAfter}</span>
                ) : null}
            </div>
            <div className={styles.iconContainer}>
                <img src={externalLink} alt="" className={styles.icon} aria-hidden />
            </div>
        </Button>
    );
}

ExternalLinkButton.propTypes = propTypes;
ExternalLinkButton.defaultProps = defaultProps;

export default ExternalLinkButton;

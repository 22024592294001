import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';

import GalleryViewer from './GalleryViewer';

import styles from '../../styles/partials/profile.module.scss';

const propTypes = {
    story: AppPropTypes.story.isRequired,
    focusable: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
    focusable: false,
};

function Profile({ story, focusable, className }) {
    const { locale } = useIntl();

    const { name, metadata = [], images = [], color = null } = story;
    const firstname = name.split(' ')[0];
    const lastname = name.split(' ')[1];

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.inner}>
                <div className={styles.left}>
                    <h2 className={styles.name} aria-hidden>
                        {firstname}&nbsp;
                        <span style={{ fontStyle: 'italic' }}>{lastname}</span>
                    </h2>
                    <div className={styles.carouselDesktop}>
                        <span
                            className={styles.carouselShadow}
                            style={{
                                boxShadow: ` 0 0 200px 150px ${color}`,
                            }}
                        />
                        <GalleryViewer
                            images={images}
                            focusable={focusable}
                            className={styles.gallery}
                        />
                    </div>
                </div>

                <div className={styles.metadata}>
                    <dl>
                        {metadata[locale].map(({ label, value }, i) => (
                            <Fragment key={`profile-${label}-${i + 1}`}>
                                <dt className={styles.label}>{label}</dt>
                                <dd
                                    className={styles.value}
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{ __html: value }}
                                />
                            </Fragment>
                        ))}
                    </dl>
                </div>
            </div>
            <div className={styles.carouselMobile}>
                <span
                    className={styles.carouselShadow}
                    style={{
                        boxShadow: ` 0 0 200px 150px ${color}`,
                    }}
                />
                <GalleryViewer images={images} onClickClose={null} className={styles.gallery} />
            </div>
        </div>
    );
}

Profile.propTypes = propTypes;
Profile.defaultProps = defaultProps;

export default Profile;

/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';

import { scenes, stories } from '../../data';
import Button from '../buttons/Button';
import MenuButton from '../buttons/Menu';
import MenuItemButton from '../buttons/MenuItem';
import { line } from '../icons/index';

import styles from '../../styles/partials/menu.module.scss';

const propTypes = {
    currentRoute: PropTypes.string,
    focusable: PropTypes.bool,
    onClickClose: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    currentRoute: null,
    focusable: false,
    onClickClose: null,
    className: null,
};

function Menu({ focusable, currentRoute, onClickClose, className }) {
    const { scene: sceneSlug = null, ...params } = useParams();
    const url = useUrlGenerator();
    const { locale, formatMessage } = useIntl();

    const otherLocaleScene = useMemo(() => {
        if (sceneSlug === null) {
            return null;
        }
        const { slug: { [locale === 'fr' ? 'en' : 'fr']: slugLocale = null } = {} } =
            scenes.find(({ slug: { [locale]: slug = null } = {} }) => slug === sceneSlug) || {};
        return slugLocale;
    }, [sceneSlug, locale]);

    return (
        <nav
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            id="menu"
            role="navigation"
        >
            <ul className={styles.items}>
                <li className={styles.item}>
                    <MenuItemButton
                        href={url('home')}
                        focusable={focusable}
                        className={styles.button}
                        onClick={onClickClose}
                    >
                        <FormattedMessage defaultMessage="Home" description="Menu item label" />
                    </MenuItemButton>
                    <img src={line} alt="" aria-hidden className={styles.line} tabIndex="-1" />
                </li>
                <li className={styles.item}>
                    <MenuItemButton
                        href={url('instructions')}
                        focusable={focusable}
                        onClick={onClickClose}
                        className={styles.button}
                    >
                        <FormattedMessage
                            defaultMessage="Instructions"
                            description="Menu item label"
                        />
                    </MenuItemButton>
                    <img src={line} alt="" aria-hidden className={styles.line} tabIndex="-1" />
                </li>
                <li className={styles.item}>
                    <MenuItemButton
                        href={url('intro')}
                        focusable={focusable}
                        onClick={onClickClose}
                        className={styles.button}
                    >
                        <FormattedMessage
                            defaultMessage="Introduction"
                            description="Menu item label"
                        />
                    </MenuItemButton>
                    <img src={line} alt="" aria-hidden className={styles.line} tabIndex="-1" />
                </li>

                <li className={styles.item} id="participants">
                    <MenuItemButton
                        href={url('participants')}
                        focusable={focusable}
                        onClick={onClickClose}
                        className={styles.button}
                    >
                        <FormattedMessage
                            defaultMessage="Participants"
                            description="Menu item label"
                        />
                    </MenuItemButton>
                    <ul className={styles.participants}>
                        {stories.map((story) => (
                            <li className={styles.participant} key={`participant-${story.id}`}>
                                <Button
                                    href={url('participant', {
                                        participant: story.id,
                                    })}
                                    className={styles.participantButton}
                                    onClick={onClickClose}
                                    focusable={focusable}
                                    aria-label={formatMessage(
                                        {
                                            defaultMessage: 'Profile of {participant}',
                                            description: 'Button label',
                                        },
                                        {
                                            participant: story.name,
                                        },
                                    )}
                                >
                                    {story.name}
                                </Button>
                                <ul className={styles.subItems}>
                                    <li className={styles.subItem}>
                                        <Button
                                            href={url('story', {
                                                participant: story.id,
                                                story: 'text',
                                            })}
                                            className={styles.storyButton}
                                            onClick={onClickClose}
                                            focusable={focusable}
                                            aria-label={formatMessage(
                                                {
                                                    defaultMessage: 'Portrait of {participant}',
                                                    description: 'Button label',
                                                },
                                                {
                                                    participant: story.name,
                                                },
                                            )}
                                        >
                                            <FormattedMessage
                                                defaultMessage="Portrait"
                                                description="Button label"
                                            />
                                        </Button>
                                    </li>

                                    <li className={styles.subItem}>
                                        <Button
                                            href={url('story', {
                                                participant: story.id,
                                                story: 'audio',
                                            })}
                                            className={styles.storyButton}
                                            onClick={onClickClose}
                                            focusable={focusable}
                                            aria-label={formatMessage(
                                                {
                                                    defaultMessage: 'Audio dream of {participant}',
                                                    description: 'Button label',
                                                },
                                                {
                                                    participant: story.name,
                                                },
                                            )}
                                        >
                                            <FormattedMessage
                                                defaultMessage="Audio dream"
                                                description="Button label"
                                            />
                                        </Button>
                                    </li>
                                </ul>
                            </li>
                        ))}
                    </ul>
                    <img src={line} alt="" aria-hidden className={styles.line} tabIndex="-1" />
                </li>
                <li className={styles.item}>
                    <MenuItemButton
                        href={url('about')}
                        focusable={focusable}
                        className={styles.button}
                        onClick={onClickClose}
                    >
                        <FormattedMessage defaultMessage="About" description="Menu item label" />
                    </MenuItemButton>
                    <img src={line} alt="" aria-hidden className={styles.line} tabIndex="-1" />
                </li>
                <li className={styles.item}>
                    <MenuItemButton
                        href={url('credits')}
                        focusable={focusable}
                        className={styles.button}
                        onClick={onClickClose}
                    >
                        <FormattedMessage defaultMessage="Credits" description="Menu item label" />
                    </MenuItemButton>
                    <img src={line} alt="" aria-hidden className={styles.line} tabIndex="-1" />
                </li>
                <li className={styles.item}>
                    <MenuItemButton
                        href={url(`${locale === 'fr' ? 'en' : 'fr'}.${currentRoute || 'home'}`, {
                            ...params,
                            scene: otherLocaleScene,
                        })}
                        external
                        target="_self"
                        language={locale}
                        focusable={focusable}
                        className={styles.languageButton}
                        onClick={onClickClose}
                    >
                        <span lang={`${locale === 'fr' ? 'en' : 'fr'}-CA`}>
                            {locale === 'fr' ? 'English version' : 'Version française'}
                        </span>
                    </MenuItemButton>
                </li>
            </ul>

            <MenuButton className={styles.menuButton} opened onClick={onClickClose} aria-expanded />
        </nav>
    );
}

Menu.propTypes = propTypes;
Menu.defaultProps = defaultProps;

export default Menu;

/* eslint-disable import/prefer-default-export */
import annEn from './AccrocheAnneEng.mp3';
import annFr from './AccrocheAnneFr.mp3';
import fraEn from './AccrocheFrancoisEng.mp3';
import fraFr from './AccrocheFrancoisFr.mp3';
import josEn from './AccrocheJosueEng.mp3';
import josFr from './AccrocheJosueFr.mp3';
import marEn from './AccrocheMarie-CEng.mp3';
import marFr from './AccrocheMarie-CFr.mp3';
import natEn from './AccrocheNathalieEng.mp3';
import natFr from './AccrocheNathalieFr.mp3';
import selEn from './AccrocheSelmaEng.mp3';
import selFr from './AccrocheSelmaFr.mp3';
import sheEn from './AccrocheSherriEng.mp3';
import sheFr from './AccrocheSherriFr.mp3';
import steEn from './AccrocheStephaneEng.mp3';
import steFr from './AccrocheStephaneFr.mp3';

export const anne = { en: annEn, fr: annFr };
export const francois = { en: fraEn, fr: fraFr };
export const josue = { en: josEn, fr: josFr };
export const mariechristine = { en: marEn, fr: marFr };
export const nathalie = { en: natEn, fr: natFr };
export const selma = { en: selEn, fr: selFr };
export const sherri = { en: sheEn, fr: sheFr };
export const stephane = { en: steEn, fr: steFr };

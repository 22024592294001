import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Helmet from 'react-helmet';

import styles from '../../styles/partials/page-title.module.scss';

const propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    title: null,
    className: null,
};

function PageTitle({ title, className }) {
    const titleRef = useRef(null);

    useEffect(() => {
        titleRef.current.focus();
    }, [title]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <Helmet>{title !== null ? <title>{title}</title> : null}</Helmet>
            <h1 className={styles.title} tabIndex={-1} ref={titleRef}>
                {title}
            </h1>
        </div>
    );
}

PageTitle.propTypes = propTypes;
PageTitle.defaultProps = defaultProps;

export default PageTitle;

/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useContext, useState, useMemo, useCallback } from 'react';

export const NavigationContext = React.createContext(null);

export const useNavigationContext = () => useContext(NavigationContext) || {};

export const useNavigation = () => {
    const { showNavigation, setShowNavigation } = useNavigationContext();
    return { showNavigation, setShowNavigation };
};

const propTypes = {
    children: PropTypes.node.isRequired,
    showNavigation: PropTypes.bool,
};

const defaultProps = {
    showNavigation: true,
};

export const NavigationProvider = ({ children, showNavigation: initialShowNavBar }) => {
    const [showNavigation, setShowNavigation] = useState(initialShowNavBar);

    const value = useMemo(
        () => ({
            showNavigation,
            setShowNavigation,
        }),
        [showNavigation, setShowNavigation],
    );
    return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
};

NavigationProvider.propTypes = propTypes;
NavigationProvider.defaultProps = defaultProps;

/* eslint-disable jsx-a11y/tabindex-no-positive */

/* eslint-disable react/jsx-props-no-spreading */
import { useWindowEvent } from '@folklore/hooks';
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';

import useAudios from '../../hooks/useAudios';
import * as AppPropTypes from '../../lib/PropTypes';
import { getComponentFromName } from '../../lib/utils';

import defaultAudioAmbiance from '../../audio/ReveAmbiance.mp3';
import { scenes } from '../../data';
import { useCurrentAmbianceAudio, usePlaybackContext } from '../context/PlaybackContext';
import * as SceneComponents from '../scenes/index';
import Circles from './Circles';
import Controls from './Controls';
import Navigation from './Navigation';

import styles from '../../styles/partials/experience.module.scss';

const propTypes = {
    stories: AppPropTypes.stories,
    scene: PropTypes.string,
    story: PropTypes.string,
    focusable: PropTypes.bool,
    paused: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    stories: [],
    scene: null,
    story: null,
    focusable: true,
    paused: false,
    className: null,
};

function Experience({
    stories,
    scene: sceneId,
    story: storyId,
    paused: experiencePaused,
    focusable,
    className,
}) {
    const intl = useIntl();
    const {
        audio,
        playing,
        setPaused,
        play,
        pause,
        muted,
        setMuted,
        lock,
        unlock,
        volume,
        setVolume,
    } = usePlaybackContext();

    const finalSceneId = sceneId || scenes[0].id;
    const scene = useMemo(
        () => scenes.find(({ id }) => id === finalSceneId) || scenes[0] || {},
        [finalSceneId],
    );
    const { component: componentName } = scene;
    const SceneComponent = getComponentFromName(SceneComponents, componentName || scene.id);

    const audioFiles = useMemo(() => {
        if (sceneId !== 'story_audio') {
            return { ambiance: defaultAudioAmbiance };
        }
        return null;
    }, [sceneId]);

    // STATE
    const [playedStories, setPlayedStories] = useState([]);
    const [activeCharacterIntro, setActiveCharacterIntro] = useState(null);
    const [withReader, setWithReader] = useState(false);
    const [withClosedCaptions, setWithClosedCaptions] = useState(false);

    const onVolumeChange = useCallback(
        (newVolume) => {
            setVolume(newVolume);
        },
        [setVolume],
    );

    useEffect(() => {
        if (scene.id !== null && (scene.id === 'story_audio' || scene.id === 'intro')) {
            setWithClosedCaptions(true);
        } else {
            setWithClosedCaptions(false);
        }
        setWithReader(false);
    }, [scene]);

    useEffect(() => {
        if (experiencePaused === playing) {
            setPaused(experiencePaused);
        }
    }, [experiencePaused, setPaused]);

    useEffect(() => {
        if (storyId !== null && playedStories.includes(storyId) === false) {
            setPlayedStories([...playedStories, storyId]);
        }
    }, [storyId, playedStories, setPlayedStories]);

    // STORY
    const story = useMemo(
        () => stories.find(({ id }) => storyId === id) || null,
        [stories, storyId],
    );

    // AUDIO AMBIANCE
    const audios = useAudios(audioFiles, { loop: false, lock, unlock, muted });
    const ambianceAudio = audios !== null ? audios.ambiance || null : null;
    useCurrentAmbianceAudio(ambianceAudio);
    useEffect(() => {
        if (ambianceAudio !== null) {
            ambianceAudio.loop(true);
        }
    }, [ambianceAudio]);

    // NAVIGATION
    const url = useUrlGenerator();
    const sceneIsStory = scene.id.match(/^story_/) || scene.id === 'profile';

    const navigationItems = useMemo(() => {
        if (scene.id === 'intro' || scene.id === 'outro') {
            return [];
        }

        if (sceneIsStory) {
            return [
                {
                    id: 'profile',
                    label: intl.formatMessage({
                        defaultMessage: 'Profile',
                        description: 'Button label',
                    }),
                    color: story.color,
                    url: url('participant', {
                        participant: storyId,
                    }),
                    order: 2,
                },
                {
                    id: 'story_audio',
                    label: intl.formatMessage({
                        defaultMessage: 'Dream Audio',
                        description: 'Button label',
                    }),
                    color: story.color,
                    url: url('story', {
                        participant: storyId,
                        story: 'audio',
                    }),
                    order: 3,
                },
                {
                    id: 'story_text',
                    label: intl.formatMessage({
                        defaultMessage: 'Portrait',
                        description: 'Button label',
                    }),
                    color: story.color,
                    played: false,
                    url: url('story', {
                        participant: storyId,
                        story: 'text',
                    }),
                    order: 4,
                },
                {
                    id: 'menu',
                    label: intl.formatMessage({
                        defaultMessage: 'Back to participants',
                        description: 'Button label',
                    }),
                    color: story.color,
                    url: url('participants'),
                    order: 1,
                },
            ];
        }
        return stories.map(({ id, name, color }) => ({
            id,
            label: name,
            color,
            played: playedStories.includes(id),
            url: url('participant', {
                participant: id,
            }),
        }));
    }, [scene, sceneIsStory, storyId, playedStories]);

    // COLORS
    const colors = useMemo(() => stories.map(({ color }) => color), []);
    const [colorIndex, setColorIndex] = useState(0);
    const nextIndex = useCallback(() => {
        setColorIndex(colorIndex === colors.length - 1 ? 0 : colorIndex + 1);
    }, [colorIndex, colors]);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
            nextIndex();
        }, 5000);

        // eslint-disable-next-line consistent-return
        return () => {
            if (interval !== null) {
                clearInterval(interval);
            }
        };
    }, [scene, colorIndex]);

    // SHORTCUTS
    const onKeyDown = useCallback(
        ({ altKey = false, code = null }) => {
            if (altKey && code === 'KeyK') {
                setPaused(playing);
            }
        },

        [playing, setPaused],
    );

    useWindowEvent('keydown', onKeyDown);

    // CONTROLS
    const onClickPause = useCallback(() => {
        pause();
    }, [pause]);

    const onClickPlay = useCallback(() => {
        play();
    }, [play]);

    const onClickMute = useCallback(() => {
        const newMuted = !muted;
        setMuted(newMuted);
    }, [muted, setMuted]);

    const onClickCaptions = useCallback(() => {
        setWithClosedCaptions(!withClosedCaptions);
    }, [withClosedCaptions, setWithClosedCaptions]);

    const clickWithReader = useCallback(() => {
        setWithReader(true);
    }, [withReader, setWithReader]);

    const storyAudioId = useMemo(
        () => (sceneIsStory ? scene.id.split('_')[1] : null),
        [sceneIsStory, scene],
    );

    const hasAmbiance = audios !== null ? (audios.ambiance || null) !== null : false;

    return (
        <div className={classNames([styles.container, { [className]: className !== null }])}>
            <SceneComponent
                paused={!playing}
                muted={muted}
                stories={stories}
                story={story}
                sceneId={sceneId}
                audioId={storyAudioId}
                focusable={focusable}
                withReader={withReader}
                setWithReader={setWithReader}
                withOutroButton={playedStories.length >= stories.length}
                activeCharacter={activeCharacterIntro}
                setActiveCharacter={setActiveCharacterIntro}
                withClosedCaptions={withClosedCaptions}
                className={styles.scene}
            />
            {!withReader ? (
                <>
                    <div className={styles.gradientBottomBackground} />
                    <Navigation
                        items={navigationItems}
                        hasMinWidth
                        focusable={focusable}
                        current={sceneId !== 'menu' ? sceneId : null}
                        className={classNames([
                            styles.navigation,
                            {
                                [styles.middle]: sceneId === 'menu',
                            },
                        ])}
                    />
                </>
            ) : null}
            {scene !== 'instructions' && withReader === false ? (
                <Controls
                    audio={audio || ambianceAudio}
                    paused={!playing}
                    muted={muted}
                    scene={scene}
                    volume={volume}
                    focusable={focusable}
                    onClickPause={onClickPause}
                    onClickPlay={onClickPlay}
                    onClickMute={onClickMute}
                    onClickCaptions={onClickCaptions}
                    storyAudioId={storyAudioId}
                    withAmbianceSound={hasAmbiance}
                    withReaderButton={storyAudioId === 'text' || storyAudioId === 'audio'}
                    withReader={clickWithReader}
                    withClosedCaptions={withClosedCaptions}
                    onVolumeChange={onVolumeChange}
                    className={styles.controls}
                />
            ) : null}
            <Circles
                stories={stories}
                color={story !== null ? story.color : colors[colorIndex]}
                scene={scene.id}
                storyAudioId={storyAudioId}
                activeCharacter={activeCharacterIntro}
                className={styles.circles}
            />
            <div className={styles.textureBackground} />
            <div className={styles.noiseBackground} />
        </div>
    );
}

Experience.propTypes = propTypes;
Experience.defaultProps = defaultProps;

export default Experience;

/* eslint-disable jsx-a11y/tabindex-no-positive */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { credits, acknowledgements } from '../../data';
import Button from '../buttons/Button';
import RestartButton from '../buttons/Restart';
import { textLogoFr, textLogoEn, externalLink } from '../icons';
import PageTitle from '../partials/PageTitle';

import styles from '../../styles/pages/credits.module.scss';

import cca from '../../images/CCA_RGB_black_f.png';
import calq from '../../images/Calq_noir.png';

const propTypes = { menuOpened: PropTypes.bool, className: PropTypes.string };

const defaultProps = { menuOpened: false, className: null };

function CreditsPage({ menuOpened, className }) {
    const url = useUrlGenerator();
    const { locale } = useIntl();
    const intl = useIntl();

    const focusable = !menuOpened;

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <PageTitle
                title={intl.formatMessage({
                    defaultMessage: 'Credits | Dream Voices',
                    description: 'Page title',
                })}
            />
            <div className={styles.inner}>
                <span className={styles.shadow} />
                <h1 className={styles.title}>
                    <FormattedMessage defaultMessage="Credits" description="Page title" />
                </h1>
                <div className={styles.section}>
                    <ul className={styles.credits}>
                        {credits.map(({ id, heading = null, values = null }, index) => (
                            <li key={`credit-${id}`} className={styles.listSection}>
                                {heading !== null && !heading.link ? (
                                    <span className={styles.heading}>{heading[locale]}</span>
                                ) : null}
                                {heading !== null && heading.link ? (
                                    <a
                                        href={heading.link}
                                        target="_blank"
                                        className={classNames([
                                            styles.heading,
                                            styles.externalLink,
                                        ])}
                                        rel="noreferrer"
                                        tabIndex={!focusable ? -1 : null}
                                    >
                                        {heading[locale]}
                                        <img
                                            src={externalLink}
                                            alt=""
                                            aria-hidden
                                            className={styles.externalIcon}
                                        />
                                    </a>
                                ) : null}

                                <dl
                                    className={classNames([
                                        styles.subcategory,
                                        {
                                            [styles.noBorder]: index === 0,
                                        },
                                    ])}
                                >
                                    {values.map(({ label = null, value, link = null }, i) => (
                                        <div
                                            key={`link-${link}-${i + 1}`}
                                            className={styles.labelGroup}
                                        >
                                            {label !== null ? (
                                                <dt className={styles.label}>{label[locale]}</dt>
                                            ) : null}
                                            {link !== null ? (
                                                <dd className={styles.value}>
                                                    <a
                                                        href={link}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className={styles.externalLink}
                                                        tabIndex={!focusable ? -1 : null}
                                                    >
                                                        {value}
                                                        <img
                                                            src={externalLink}
                                                            alt=""
                                                            aria-hidden
                                                            className={styles.externalIcon}
                                                        />
                                                    </a>
                                                </dd>
                                            ) : (
                                                <dd className={styles.value}>{value}</dd>
                                            )}
                                        </div>
                                    ))}
                                </dl>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={styles.section}>
                    <h2 className={styles.subheader}>
                        <FormattedMessage
                            defaultMessage="Financial Partners"
                            description="Credits text"
                        />
                    </h2>
                    <div className={styles.partnersImages}>
                        <div className={styles.logoContainer}>
                            <img
                                src={cca}
                                alt={intl.formatMessage({
                                    defaultMessage: 'Canada Council for the Arts',
                                    description: 'Image alt',
                                })}
                            />
                            <span className={styles.acknowledgement}>
                                <FormattedMessage
                                    defaultMessage="We acknowledge the support of the Canada Council for the Arts."
                                    description="Text"
                                />
                            </span>
                        </div>
                        <div className={styles.logoContainer}>
                            <img
                                src={calq}
                                alt={intl.formatMessage({
                                    defaultMessage: 'Conseil des arts et lettres du Québec',
                                    description: 'Image alt',
                                })}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.section}>
                    <h2 className={styles.subheader}>
                        <FormattedMessage
                            defaultMessage="Acknowledgements"
                            description="Credits text"
                        />
                    </h2>
                    <ul className={styles.acknowledgements}>
                        {acknowledgements.map((item, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <li key={`ack-${index}`} className={styles.value}>
                                {item}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={styles.section}>
                    <p className={styles.original}>
                        <FormattedMessage
                            defaultMessage="Dream Voices is an original concept by Yves Amyot, Réjane Bougé and produced by the centre Turbine."
                            description="Credits text"
                        />
                    </p>
                </div>
            </div>
            <div className={styles.restartContainer}>
                <RestartButton className={styles.restart} focusable={focusable} href={url('home')}>
                    <FormattedMessage
                        defaultMessage="Restart the Experience"
                        description="Button text"
                    />
                </RestartButton>
            </div>
            <Button href={url('home')} className={styles.logo} focusable={focusable}>
                <span className={styles.logoShadow} />
                <img
                    alt={intl.formatMessage({
                        defaultMessage: 'Dream Voices (back to Home)',
                        description: 'Logo label',
                    })}
                    src={locale === 'fr' ? textLogoFr : textLogoEn}
                    className={styles.logoIcon}
                />
            </Button>
        </div>
    );
}

CreditsPage.propTypes = propTypes;
CreditsPage.defaultProps = defaultProps;

export default CreditsPage;

/* eslint-disable react/no-danger */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import NavigationButton from '../buttons/Navigation';
import GradientCircle from './GradientCircle';

import styles from '../../styles/partials/navigation.module.scss';

const propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            played: PropTypes.bool,
        }),
    ),
    current: PropTypes.string,
    visible: PropTypes.bool,
    focusable: PropTypes.bool,
    hasMinWidth: PropTypes.bool,
    onClickItem: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    items: [
        { id: '1', label: 'One', color: '#A77F78' },
        { id: '2', label: 'Two', color: '#89867C' },
        { id: '3', label: 'Three', played: true, color: '#9D8D58' },
        { id: '4', label: 'Four', color: '#9EA778' },
        { id: '5', label: 'Five', color: '#8C5D74' },
        { id: '6', label: 'Six', color: '#45438E' },
        { id: '7', label: 'Seven', color: '#4A6895' },
        { id: '8', label: 'Eight', color: '#707070' },
    ],
    current: null,
    visible: true,
    focusable: true,
    hasMinWidth: false,
    onClickItem: null,
    className: null,
};

function Navigation({ items, current, visible, focusable, hasMinWidth, onClickItem, className }) {
    const currentItemIndex = current !== null ? items.findIndex((item) => item.id === current) : -1;
    const currentItem = current !== null ? items.find((item) => item.id === current) || null : null;
    const finalCurrentItemIndex =
        currentItem !== null && typeof currentItem.order !== 'undefined'
            ? currentItem.order - 1
            : currentItemIndex;
    const handlePosition =
        finalCurrentItemIndex !== -1
            ? 100 * ((finalCurrentItemIndex + 1) / (items.length + 1))
            : null;
    const count = items.length;

    const allPlayed = useMemo(() => items.every((item) => item.played === true), [items]);

    return (
        <nav
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                    [styles.visible]: visible,
                },
            ])}
            role="navigation"
        >
            <div
                className={classNames([
                    styles.timeline,
                    {
                        [styles.minWidth]: hasMinWidth,
                    },
                ])}
            >
                {current !== null && items.length > 0 ? (
                    <div
                        className={styles.handle}
                        style={{
                            left: `${handlePosition}%`,
                        }}
                    >
                        <div
                            className={styles.handleInner}
                            style={{
                                backgroundColor: currentItem != null ? currentItem.color : null,
                            }}
                        />
                    </div>
                ) : null}
                {items !== null && items.length > 0 ? (
                    <ul className={styles.items}>
                        <li
                            className={classNames([
                                styles.item,
                                {
                                    [styles.nextPlayed]: items[0].played || false,
                                },
                            ])}
                            style={{
                                width: `${100 / (count + 1)}%`,
                            }}
                        >
                            <div className={styles.progress}>
                                <div className={styles.line} />
                            </div>
                        </li>
                        {items.map(
                            ({ id, url, label, color, played = false, order = null }, index) => (
                                <li
                                    key={id}
                                    className={classNames([
                                        styles.item,
                                        {
                                            [styles.played]: played,
                                            [styles.allPlayed]: allPlayed,
                                            [styles.nextPlayed]:
                                                index < count - 1 &&
                                                (items[index + 1].played || false),
                                        },
                                    ])}
                                    style={{
                                        width: `${100 / (count + 1)}%`,
                                        order,
                                        zIndex: order || index,
                                    }}
                                >
                                    <div className={styles.progress}>
                                        <div className={styles.line} />
                                    </div>

                                    <NavigationButton
                                        className={styles.button}
                                        href={url}
                                        aria-current={currentItem !== null && id === currentItem.id}
                                        focusable={focusable}
                                        withBack={id === 'menu'}
                                        onClick={() => {
                                            if (onClickItem !== null) {
                                                onClickItem(id);
                                            }
                                        }}
                                    >
                                        <GradientCircle
                                            color={id === 'menu' ? '#212121' : color}
                                            played={played}
                                            aria-hidden="true"
                                            className={styles.gradient}
                                        />
                                        <div className={styles.label}>
                                            <span className={styles.dash} aria-hidden>
                                                —
                                            </span>
                                            <span className={styles.labelTitle}>
                                                <span className={styles.labelBackground}>
                                                    {label}
                                                </span>
                                            </span>
                                        </div>
                                    </NavigationButton>
                                </li>
                            ),
                        )}
                    </ul>
                ) : null}
            </div>
        </nav>
    );
}

Navigation.propTypes = propTypes;
Navigation.defaultProps = defaultProps;

export default Navigation;

import { useCallback, useMemo } from 'react';
import { useDrag } from 'react-use-gesture';

const useSwipe = ({
    onSwipeLeft = null,
    onSwipeRight = null,
    onSwipeUp = null,
    onSwipeDown = null,
    distance = [20, 20],
    velocity = [0.2, 0.2],
} = {}) => {
    const onDrag = useCallback(
        ({ swipe: [swipeX, swipeY] }) => {
            if (swipeX === -1 && onSwipeLeft !== null) {
                onSwipeLeft();
            } else if (swipeX === 1 && onSwipeRight !== null) {
                onSwipeRight();
            } else if (swipeY === -1 && onSwipeUp !== null) {
                onSwipeUp();
            } else if (swipeY === 1 && onSwipeDown !== null) {
                onSwipeDown();
            }
        },
        [onSwipeLeft, onSwipeRight, onSwipeUp, onSwipeDown],
    );
    const opts = useMemo(
        () => ({
            swipeDistance: distance,
            swipeVelocity: velocity,
        }),
        [distance, velocity],
    );
    return useDrag(onDrag, opts);
};

export default useSwipe;

/* eslint-disable react/no-danger */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import useKeyboardKeys, { KEYS } from '../../hooks/useKeyboardKeys';

// import * as AppPropTypes from '../../lib/PropTypes';
import BackButton from '../buttons/Back';
import { usePlaybackContext } from '../context/PlaybackContext';

import styles from '../../styles/partials/reader.module.scss';

const propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    onClickBack: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    title: null,
    body: null,
    onClickBack: null,
    className: null,
};

const Reader = ({ title, body, onClickBack, className }) => {
    const { playing, setPaused } = usePlaybackContext();

    useEffect(() => {
        if (playing) {
            setPaused(true);
        }
    }, [playing, setPaused]);

    useKeyboardKeys({
        [KEYS.ESCAPE]: onClickBack !== null ? onClickBack : () => {},
    });

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.inner}>
                <BackButton className={styles.back} shadow={false} onClick={onClickBack}>
                    <FormattedMessage defaultMessage="Back" description="Button label" />
                </BackButton>
                <h1 className={styles.title}>{title}</h1>
                <div className={styles.text} dangerouslySetInnerHTML={{ __html: body }} />
                <BackButton className={styles.back} shadow={false} onClick={onClickBack}>
                    <FormattedMessage defaultMessage="Back" description="Button label" />
                </BackButton>
            </div>
        </div>
    );
};

Reader.propTypes = propTypes;
Reader.defaultProps = defaultProps;

export default Reader;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { reader } from '../icons';

import Button from './Button';
import styles from '../../styles/buttons/reader.module.scss';

const propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

const defaultProps = {
    children: null,
    className: null,
};

function ReaderButton({ className, children, ...props }) {
    return (
        <Button
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            {...props}
        >
            <div className={styles.iconContainer}>
                <img src={reader} alt="" className={styles.icon} aria-hidden />
            </div>
            <div className={styles.children}>{children}</div>
        </Button>
    );
}

ReaderButton.propTypes = propTypes;
ReaderButton.defaultProps = defaultProps;

export default ReaderButton;

import { Howl, Howler } from 'howler';
import isString from 'lodash/isString';
import { useMemo, useEffect } from 'react';

Howler.autoUnlock = true;

function createAudio(src, loop = false, lock = null, unlock = null, muted = false) {
    const audio = new Howl({
        src: [src],
        html5: true,
        loop,
        autoplay: false,
        mute: muted,
        onplay: () => {
            // // console.log('play!', audio._muted);
            // if (unlock !== null) {
            //     unlock();
            // }
        },
        onplayerror() {
            // if (lock !== null) {
            //     lock();
            // }
            // audio.once('unlock', () => {
            //     if (unlock !== null) {
            //         unlock();
            //     }
            //     audio.play();
            // });
        },
    });

    audio.getCurrentTime = () => {
        // eslint-disable-next-line no-underscore-dangle
        const [sound = null] = (audio || {})._sounds || [];
        const { _node: node } = sound || {};
        if (node) {
            return node.currentTime;
        }
        return null;
    };

    return audio;
}

function destroyAudio(audio) {
    if (!audio.paused) {
        audio.pause();
    }
    audio.stop();
    audio.unload();
}

function useAudios(audios, options) {
    const { loop = false, lock = null, unlock = null, muted = false } = options || {};
    const audiosMap = useMemo(() => {
        if (audios === null) {
            return null;
        }
        return isString(audios)
            ? createAudio(audios, loop, lock, unlock, muted)
            : Object.keys(audios)
                  .filter((id) => (audios[id] || null) !== null)
                  .reduce(
                      (map, id) => ({
                          ...map,
                          [id]: createAudio(
                              audios[id],
                              id.match(/_loop$/) !== null,
                              lock,
                              unlock,
                              muted,
                          ),
                      }),
                      {},
                  );
    }, [audios, loop, lock, unlock]);

    useEffect(
        () => () => {
            if (audios === null) {
                return;
            }
            if (isString(audios)) {
                destroyAudio(audiosMap);
            } else {
                Object.keys(audiosMap).forEach((id) => {
                    destroyAudio(audiosMap[id]);
                });
            }
        },
        [audios, audiosMap],
    );

    return audiosMap;
}

export default useAudios;

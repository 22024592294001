/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useContext, useState, useMemo, useCallback, useEffect } from 'react';

export const PlaybackContext = React.createContext(null);

export const usePlaybackContext = () => useContext(PlaybackContext) || {};

export const useCurrentAudio = (audio) => {
    const { setAudio } = usePlaybackContext();
    useEffect(() => {
        setAudio(audio);
        return () => {
            setAudio(null);
        };
    }, [audio]);
};

export const useCurrentAmbianceAudio = (audio) => {
    const { setAmbianceAudio } = usePlaybackContext();
    useEffect(() => {
        setAmbianceAudio(audio);
        return () => {
            setAmbianceAudio(null);
        };
    }, [audio]);
};

const propTypes = {
    children: PropTypes.node.isRequired,
    playing: PropTypes.bool,
    muted: PropTypes.bool,
    volume: PropTypes.number,
};

const defaultProps = {
    playing: false,
    muted: false,
    volume: 1,
};

export const PlaybackProvider = ({
    children,
    playing: initialPlaying,
    muted: initialMuted,
    volume: initialVolume,
}) => {
    const [audio, setAudio] = useState(null);
    const [ambianceAudio, setAmbianceAudio] = useState(null);
    // Playback state
    const [unlocked, setUnlocked] = useState(true);
    const [paused, setPaused] = useState(!initialPlaying);
    const [playing, setPlaying] = useState(initialPlaying);
    const [muted, setMutedState] = useState(initialMuted);
    const [volume, setVolumeState] = useState(initialVolume);

    const unlock = useCallback(() => {
        setUnlocked(true);
    }, [setUnlocked]);

    const lock = useCallback(() => {
        setUnlocked(false);
    }, [setUnlocked]);

    const pause = useCallback(() => {
        if (paused) {
            setPaused(false);
        }
        if (audio !== null && audio.playing()) {
            audio.pause();
        } else if (audio === null && ambianceAudio !== null && ambianceAudio.playing()) {
            ambianceAudio.pause();
        }
    }, [audio, ambianceAudio, setPaused]);

    const play = useCallback(() => {
        if (paused) {
            setPaused(false);
        } else if (audio !== null && !audio.playing()) {
            audio.play();
        } else if (audio === null && ambianceAudio !== null && !ambianceAudio.playing()) {
            ambianceAudio.play();
        }
    }, [audio, ambianceAudio, paused, setPaused]);

    const setMuted = useCallback(
        (newMuted) => {
            setMutedState(newMuted);
            if (!newMuted && volume === 0) {
                setVolumeState(1);
            } else if (newMuted && volume > 0) {
                setVolumeState(0);
            }
        },
        [setMutedState, setVolumeState, volume],
    );

    const setVolume = useCallback(
        (newVolume) => {
            setVolumeState(newVolume);
            if (newVolume === 0) {
                setMutedState(true);
            } else if (newVolume > 0) {
                setMutedState(false);
            }
        },
        [setVolumeState, setMutedState],
    );

    useEffect(() => {
        if (audio === null) {
            return;
        }

        if (paused && audio.playing()) {
            audio.pause();
        } else if (!paused && !audio.playing()) {
            try {
                audio.play();
            } catch (e) {}
        }
    }, [audio, paused]);

    useEffect(() => {
        if (audio === null) {
            return;
        }
        audio.volume(volume);
    }, [audio, volume]);

    useEffect(() => {
        if (audio === null) {
            return;
        }
        audio.mute(muted);
    }, [audio, muted]);

    useEffect(() => {
        if (audio === null && ambianceAudio === null) {
            return () => {};
        }
        const finalAudio = audio || ambianceAudio;
        let locked = false;
        function onEnd() {
            setPlaying(false);
        }
        function onPause() {
            setPlaying(false);
        }
        function onStop() {
            setPlaying(false);
        }
        function onPlay() {
            if (!locked) {
                setPlaying(true);
            }
        }
        function onPlayError() {
            setUnlocked(false);
            locked = true;
            setPlaying(false);
        }
        function onUnlock() {
            setUnlocked(true);
            locked = false;
            if (finalAudio.playing()) {
                setPlaying(true);
            }
        }
        finalAudio.on('end', onEnd);
        finalAudio.on('pause', onPause);
        finalAudio.on('stop', onStop);
        finalAudio.on('play', onPlay);
        finalAudio.on('playerror', onPlayError);
        finalAudio.on('unlock', onUnlock);
        return () => {
            finalAudio.off('end', onEnd);
            finalAudio.off('pause', onPause);
            finalAudio.off('stop', onStop);
            finalAudio.off('play', onPlay);
            finalAudio.off('playerror', onPlayError);
            finalAudio.off('unlock', onUnlock);
        };
    }, [audio, ambianceAudio, setPlaying, setUnlocked]);

    useEffect(() => {
        if (ambianceAudio === null) {
            return;
        }

        if (paused && ambianceAudio.playing()) {
            ambianceAudio.pause();
        } else if (!paused && !ambianceAudio.playing()) {
            try {
                ambianceAudio.play();
            } catch (e) {}
        }
    }, [ambianceAudio, paused]);

    useEffect(() => {
        if (ambianceAudio === null) {
            return;
        }
        if (muted !== ambianceAudio.mute()) {
            ambianceAudio.mute(muted);
        }
        const ambianceVolume = volume * 0.5;
        if (ambianceVolume !== ambianceAudio.volume()) {
            ambianceAudio.volume(ambianceVolume);
        }
    }, [ambianceAudio, muted, volume]);

    const value = useMemo(
        () => ({
            audio,
            setAudio,
            ambianceAudio,
            setAmbianceAudio,
            lock,
            unlock,
            unlocked,
            playing,
            muted,
            play,
            pause,
            setPaused,
            setMuted,
            setVolume,
            volume,
        }),
        [
            audio,
            setAudio,
            ambianceAudio,
            setAmbianceAudio,
            lock,
            unlock,
            unlocked,
            playing,
            muted,
            setPaused,
            play,
            pause,
            setMuted,
            setVolume,
            volume,
        ],
    );

    return <PlaybackContext.Provider value={value}>{children}</PlaybackContext.Provider>;
};

PlaybackProvider.propTypes = propTypes;
PlaybackProvider.defaultProps = defaultProps;

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import useAudios from '../../hooks/useAudios';
import * as AppPropTypes from '../../lib/PropTypes';

import { useCurrentAudio, usePlaybackContext } from '../context/PlaybackContext';
import PageTitle from '../partials/PageTitle';
import ProfilePartial from '../partials/Profile';

import styles from '../../styles/scenes/profile.module.scss';

const propTypes = {
    story: AppPropTypes.story.isRequired,
    muted: PropTypes.bool,
    focusable: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    muted: false,
    focusable: false,
    className: null,
};

function Profile({ story, muted, focusable, className }) {
    const { lock, unlock } = usePlaybackContext();

    const { locale } = useIntl();
    const intl = useIntl();
    const audio = useAudios(story.audioEnter[locale] || null, { loop: false, lock, unlock, muted });
    useCurrentAudio(audio);

    useEffect(() => {
        if (audio === null) {
            return () => {};
        }

        return () => {
            audio.pause();
            audio.seek(0);
        };
    }, [audio]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <PageTitle
                title={intl.formatMessage(
                    {
                        defaultMessage: "{name}'s Profile | Experience | Dream Voices",
                        description: 'Page title',
                    },
                    { name: story.name !== null ? story.name : 'Participant' },
                )}
            />
            <div className={styles.inner}>
                <ProfilePartial story={story} focusable={focusable} className={styles.profile} />
            </div>
        </div>
    );
}

Profile.propTypes = propTypes;
Profile.defaultProps = defaultProps;

export default Profile;

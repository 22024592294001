import { useState, useEffect } from 'react';

function useAudioDuration(audio) {
    const [duration, setDuration] = useState(0);

    useEffect(() => {
        if (audio === null) {
            setDuration(0);
            return () => {};
        }
        setDuration(audio.duration());
        function onLoad() {
            setDuration(audio.duration());
        }
        audio.on('load', onLoad);
        audio.on('play', onLoad);
        return () => {
            audio.off('load', onLoad);
            audio.off('play', onLoad);
        };
    }, [audio]);

    return duration;
}

export default useAudioDuration;

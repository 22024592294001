import PropTypes from 'prop-types';
// import raf from 'raf';
import React, { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';

// import tinycolor from 'tinycolor2';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
    endPosition: PropTypes.number,
    onEnd: PropTypes.func,
    duration: PropTypes.number,
};

const defaultProps = {
    color: '#45438e',
    endPosition: 0.7,
    duration: 10000,
    onEnd: null,
    className: null,
};

function LogoFr({ color, endPosition, duration, onEnd, className }) {
    const intl = useIntl();

    // const [currentColor, setCurrentColor] = useState(color);
    const [progress, setProgress] = useState(0);
    // const progressRef = useRef(progress);
    // useEffect(() => {
    //     if (color === currentColor) {
    //         return () => {};
    //     }
    //     let canceled = false;
    //     let currentTime = new Date().getTime();

    //     function updateProgress(newProgress) {
    //         progressRef.current = newProgress;
    //         setProgress(newProgress);
    //         return newProgress;
    //     }

    //     let currentProgress = updateProgress(0);
    //     let handle = raf(function loop() {
    //         if (canceled) {
    //             return;
    //         }
    //         const newTime = new Date().getTime();
    //         const elapsed = newTime - currentTime;
    //         const step = elapsed / duration;
    //         currentProgress = updateProgress(Math.min(currentProgress + step, 1));
    //         currentTime = newTime;
    //         if (currentProgress < 1) {
    //             handle = raf(loop);
    //         } else {
    //             setCurrentColor(color);
    //             setProgress(0);
    //         }
    //     });

    //     return () => {
    //         canceled = true;
    //         if (handle !== null) {
    //             raf.cancel(handle);
    //         }
    //     };
    // }, [color, endPosition]);

    // useEffect(() => {
    //     if (onEnd !== null) {
    //         onEnd(currentColor);
    //     }
    // }, [currentColor]);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="1596"
            height="949.245"
            viewBox="0 0 1596 949.245"
            aria-label={intl.formatMessage({
                defaultMessage: 'Dream Voices logo',
                description: 'Logo label',
            })}
            className={className}
        >
            <defs>
                <radialGradient
                    id="radial-gradient-logo"
                    cx="0.5"
                    cy="0.5"
                    r="0.5"
                    gradientTransform="matrix(0, -1, 1.164, 0, -0.082, 1)"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor={color} />
                    <stop
                        offset={progress * endPosition}
                        // stopColor={
                        //     currentColor !== color
                        //         ? tinycolor.mix(currentColor, color, progress * 100)
                        //         : currentColor
                        // }

                        stopColor="#d6fd97"
                        // stopColor="#4A6895"
                    />

                    <stop
                        offset={endPosition}
                        // stopColor={
                        //     (color !== currentColor) !== null
                        //         ? tinycolor.mix(
                        //               currentColor,
                        //               color,
                        //               Math.max(0, (progress - 0.5) / 0.5) * 100,
                        //           )
                        //         : currentColor
                        // }
                        // stopColor="#4A6895"
                        // stopColor="#89867C"
                        stopColor="#819B96"
                    />
                    <stop offset="1" stopColor="#212121" />
                </radialGradient>
                <clipPath id="clip-path-logo">
                    <path
                        d="M9.85,308.1a530.781,530.781,0,0,0,50.983,79.465c26.9,34.84,63.711,73.59,109.537,101.186l14.453-20.361c35.486,17.881,65.621,24.344,112.851,24.344,166.473,0,268.413-153.248,286.5-182.735a.557.557,0,0,0,.092-.163q.522-.938.9-1.737c-11.3-15.913-77.04-103.077-187.112-139.884a315.13,315.13,0,0,0-100.551-16.445C127.078,151.769,24.258,287.807,9.85,308.1"
                        transform="translate(-9.85 -151.769)"
                        fill="url(#radial-gradient-logo)"
                    />
                </clipPath>
            </defs>
            <g transform="translate(-142 -37.816)">
                <g transform="translate(158 278.974)">
                    <g transform="translate(-16 -241.159)">
                        <g transform="translate(0)">
                            <g>
                                <path
                                    d="M206.816,155.82H375.827v4.448L193.474,561.3h-8.155L0,160.268V155.82H182.354v4.448l-34.1,30.392L243.138,410.08h4.448l10.378-28.911q10.373-31.863,10.378-70.421,0-47.431-16.679-88.583t-44.847-61.9Z"
                                    transform="translate(0 9.487)"
                                    fill="url(#radial-gradient-logo)"
                                />
                                <path
                                    d="M1170.016,541.45l.742-325.423-34.1-31.133v-4.448h165.309v35.581l-1.488,325.423,34.1,28.911v4.448h-199.4v-4.448Zm16.677-422.9q-18.175-16.677-18.161-44.106,0-26.687,18.161-42.994t47.812-16.309q28.157,0,45.588,16.309,17.409,16.313,17.421,42.994,0,27.433-17.421,44.106t-45.588,16.679q-29.651,0-47.812-16.679"
                                    transform="translate(-171.643 -15.14)"
                                    fill="url(#radial-gradient-logo)"
                                />
                                <path
                                    d="M1685.753,325.574l139.358,220.9v3.706H1630.156v-4.447L1665,515.342l-78.577-126.758q-8.9,13.342-14.087,32.246A143.391,143.391,0,0,0,1567.145,459q0,27.433,11.121,49.665t35.581,35.583v5.929H1441.133v-4.447l140.1-166.047L1444.84,160.268V155.82h191.25v4.448l-34.846,30.393,79.32,126.019q6.668-13.345,11.119-32.247a166.146,166.146,0,0,0,4.449-38.175q0-27.417-10.379-50.037a76.339,76.339,0,0,0-32.617-35.211V155.82H1817.7v4.448Z"
                                    transform="translate(-229.111 9.487)"
                                    fill="url(#radial-gradient-logo)"
                                />
                            </g>
                        </g>
                    </g>
                    <g transform="translate(49.44 330.357)">
                        <g transform="translate(0 0)">
                            <g>
                                <path
                                    d="M2372.578,174.58a240.9,240.9,0,0,1-23.789,43.53q-14.745,21.652-31.871,21.645a20.947,20.947,0,0,1-11.658-3.568,77.868,77.868,0,0,1-12.128-10.228,86.919,86.919,0,0,0-10.942-9.514,17.2,17.2,0,0,0-9.991-3.33q-2.388,0-5.945,3.33a22.169,22.169,0,0,0-5.473,8.086V385.8l21.882,18.078v2.854H2154.7v-2.854l22.355-18.553V207.406l-35.681,17.6-1.9-2.379,9.988-14.271q15.705-22.356,25.212-34.015a100.968,100.968,0,0,1,20.458-19.266q10.932-7.6,22.832-7.612,17.128,0,26.881,11.655t9.748,40.675q0,9.043-.475,13.8h3.8l12.375-17.125q17.594-24.732,27.35-37.108t14.511-12.369q3.324,0,8.564,4.044t10.462,8.8a152.432,152.432,0,0,0,15.7,13.32q6.655,4.761,12.843,4.758a14.912,14.912,0,0,0,5.945-.952,13.457,13.457,0,0,0,4.522-3.806Z"
                                    transform="translate(-2139.475 -36.143)"
                                    fill="url(#radial-gradient-logo)"
                                />
                                <path
                                    d="M2779.9,303.039q-68.038,74.693-90.86,74.69-38.072,0-68.981-15.937a119.022,119.022,0,0,1-48.522-45.194q-17.6-29.257-17.6-68.743,0-50.9,24.733-81.825t55.419-43.291q30.686-12.366,50.189-12.369,42.825,0,66.126,23.548t26.165,61.608q0,6.187-16.176,16.412a207.457,207.457,0,0,1-36.627,18.078q-20.463,7.849-30.45,7.849h-60.889q14.736,33.3,40.433,54.709t59.94,21.408a71,71,0,0,0,20.694-3.093,145.969,145.969,0,0,0,24.029-10.228ZM2607.211,99.9l-1.908-2.854L2671.915,0h3.807l64.209,97.049-1.418,2.854L2672.859,62.8h-1.426Zm18.313,43.291q-7.373,10.95-7.37,31.4,0,25.689,10.937,54.709h62.328q-.49-27.117-6.9-49.238-6.432-22.122-17.128-34.967t-23.548-12.845q-10.949,0-18.319,10.942"
                                    transform="translate(-2311.128)"
                                    fill="url(#radial-gradient-logo)"
                                />
                                <path
                                    d="M3108.7,155.82h108.465v2.854L3100.136,416.043H3094.9L2975.969,158.674V155.82H3093v2.854l-21.88,19.5,60.887,140.816h2.852l6.661-18.554q6.659-20.449,6.658-45.194a149.907,149.907,0,0,0-10.7-56.85q-10.705-26.4-28.783-39.723Z"
                                    transform="translate(-2487.269 -38.315)"
                                    fill="url(#radial-gradient-logo)"
                                />
                                <path
                                    d="M3632.177,307.538q-68.042,74.693-90.868,74.69-38.054,0-68.974-15.937a118.955,118.955,0,0,1-48.525-45.194q-17.608-29.257-17.6-68.743,0-50.9,24.736-81.825t55.424-43.291q30.683-12.366,50.187-12.37,42.813,0,66.125,23.549t26.169,61.607q0,6.189-16.178,16.413a207.18,207.18,0,0,1-36.632,18.078q-20.454,7.849-30.445,7.849H3484.7q14.73,33.3,40.435,54.709,25.684,21.407,59.937,21.408a71,71,0,0,0,20.691-3.092,145.566,145.566,0,0,0,24.027-10.229ZM3477.8,147.693q-7.385,10.949-7.373,31.4,0,25.687,10.937,54.708h62.322q-.475-27.116-6.9-49.238T3519.668,149.6q-10.706-12.844-23.551-12.845-10.953,0-18.314,10.942m69.688-129.4h94.192v2.854L3518.953,104.4h-4.279Z"
                                    transform="translate(-2665.38 -4.499)"
                                    fill="url(#radial-gradient-logo)"
                                />
                                <path
                                    d="M4069.971,339.028q-68.027,74.692-90.86,74.69-38.058,0-68.979-15.937a119.069,119.069,0,0,1-48.528-45.194q-17.593-29.258-17.595-68.743,0-50.9,24.736-81.825t55.414-43.291q30.692-12.366,50.192-12.369,42.821,0,66.127,23.548t26.172,61.608q0,6.188-16.178,16.412A207.232,207.232,0,0,1,4013.836,266q-20.454,7.849-30.445,7.849H3922.5q14.737,33.3,40.444,54.709,25.677,21.409,59.937,21.408a71.016,71.016,0,0,0,20.691-3.092,145.737,145.737,0,0,0,24.027-10.228ZM3915.608,179.183q-7.385,10.949-7.377,31.4,0,25.689,10.94,54.709h62.323q-.49-27.117-6.9-49.238t-17.126-34.967q-10.7-12.844-23.55-12.845-10.938,0-18.309,10.942"
                                    transform="translate(-2845.432 -35.989)"
                                    fill="url(#radial-gradient-logo)"
                                />
                                <path
                                    d="M4435.9,250.562q22.374,15.231,34.5,25.451a94.032,94.032,0,0,1,20.22,23.787q8.087,13.558,8.087,31.635a80.225,80.225,0,0,1-9.516,37.346q-9.523,18.319-32.352,30.684t-59.464,12.369a172.029,172.029,0,0,1-23.543-1.427q-10.239-1.427-25.455-4.758c-2.546-.312-7.132-1.108-13.8-2.378a104.41,104.41,0,0,0-19.5-1.9,96.168,96.168,0,0,0-15.7,1.427l-9.988-132.728h2.856a277.85,277.85,0,0,0,46.145,64.461q27.583,28.789,54.944,44.956t45.912,16.175a16.49,16.49,0,0,0,10.934-3.568q4.285-3.569,4.284-11.18,0-8.084-9.756-17.84t-36.386-26.879l-55.66-37.106q-26.648-17.6-39.493-34.967-12.825-17.36-12.84-40.2,0-35.679,26.168-57.326,26.149-21.642,68.022-21.645,38.057,0,66.6,16.175l22.361-18.553h2.849V264.835H4483q-21.883-34.722-45.192-58.277t-43.524-34.966q-20.231-11.415-33.545-11.416-15.694,0-15.7,12.844,0,8.094,7.377,15.937t23.081,18.316Z"
                                    transform="translate(-3026.174 -35.057)"
                                    fill="url(#radial-gradient-logo)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
                <g>
                    <g transform="translate(478.023 116.097)">
                        <g transform="translate(0 36.228)">
                            <g transform="translate(0)">
                                <path
                                    d="M597.953,284.7s-.591,1.234-1.772,3.516c-14.7-20.4-119.6-153.108-293.416-153.108C128.922,135.1,24.044,267.811,9.349,288.212c-1.182-2.282-1.772-3.516-1.772-3.516S114.912,62.309,302.765,62.309C490.6,62.309,597.953,284.7,597.953,284.7"
                                    transform="translate(-7.576 -56.518)"
                                    fill="url(#radial-gradient-logo)"
                                />
                                <path
                                    d="M582.85,291a5.788,5.788,0,0,1-4.721-2.439c-7.661-10.787-36.361-48.764-84.078-84.489-61.509-46.051-128.418-69.4-198.863-69.4s-137.366,23.348-198.869,69.4c-47.7,35.715-76.409,73.7-84.07,84.489a5.791,5.791,0,0,1-9.879-.719c-1.212-2.375-1.819-3.66-1.819-3.66a5.809,5.809,0,0,1,.009-4.958c1.09-2.287,27.23-56.68,76.545-111.96C142.775,93.642,218.188,54.73,295.188,54.73s152.4,38.912,218.076,112.53c49.316,55.28,75.463,109.671,76.554,111.96a5.809,5.809,0,0,1,.009,4.958s-.607,1.285-1.818,3.66a5.79,5.79,0,0,1-4.737,3.142c-.14.011-.283.016-.42.016M295.188,66.311c-92.892,0-165.059,58.969-209.246,108.438a533.763,533.763,0,0,0-52.788,70.487A433.268,433.268,0,0,1,89.376,194.8c63.554-47.581,132.8-71.709,205.812-71.709s142.247,24.127,205.8,71.709a433.148,433.148,0,0,1,56.227,50.437,533.513,533.513,0,0,0-52.793-70.483C460.238,125.28,388.068,66.311,295.188,66.311"
                                    transform="translate(0 -54.73)"
                                    fill="url(#radial-gradient-logo)"
                                />
                                <path
                                    d="M633.739,358.849"
                                    transform="translate(-51.885 -126.473)"
                                    fill="url(#radial-gradient-logo)"
                                />
                                <path
                                    d="M576.519,306.7c-16.2-22.008-79.6-100.509-182.081-134.777a310.277,310.277,0,0,0-98.714-16.146c-70.455,0-137.366,23.348-198.87,69.4-47.7,35.715-76.409,73.7-84.07,84.49a5.79,5.79,0,1,1-9.441-6.7c7.908-11.138,37.52-50.331,86.569-87.057,63.554-47.582,132.8-71.71,205.812-71.71a321.871,321.871,0,0,1,102.39,16.746c111.228,37.191,177.781,124.829,189.994,142.017a5.792,5.792,0,0,1,.5,5.857c-.287.6-.62,1.244-1,1.939-.084.162-.179.325-.28.486l0,0"
                                    transform="translate(-0.536 -75.834)"
                                    fill="url(#radial-gradient-logo)"
                                />
                                <path
                                    d="M440.881,326.188c0,45.813-35.339,82.951-78.928,82.951S283.024,372,283.024,326.188s35.338-82.951,78.929-82.951h0c43.591,0,78.927,37.14,78.927,82.951"
                                    transform="translate(-66.77 -99.2)"
                                    fill="url(#radial-gradient-logo)"
                                />
                                <path
                                    d="M235.7,569.449a5.777,5.777,0,0,1-2.549-.595c-1.224-.6-2.45-1.237-3.687-1.877l-.817-.422a5.79,5.79,0,1,1,5.311-10.291l.826.426c1.168.605,2.321,1.2,3.475,1.768a5.792,5.792,0,0,1-2.558,10.991"
                                    transform="translate(-53.201 -172.891)"
                                    fill="url(#radial-gradient-logo)"
                                />
                                <g transform="translate(594.453 480.804) rotate(180)">
                                    <g transform="translate(0 0)">
                                        <path
                                            d="M20306.359,2112.973c-1.17-2.3-1.758-3.54-1.758-3.54a5.927,5.927,0,0,1-.551-2.423v-.1a5.83,5.83,0,0,1,.561-2.433c1.092-2.292,27.232-56.681,76.545-111.961,65.674-73.618,141.084-112.532,218.084-112.532,41.715,0,82.969,11.427,122.459,33.6l28.705,19.9-57.439,78.554a474.673,474.673,0,0,0-93.725-9.194c-125.07,0-213.721,47.021-257.754,80.073a345.84,345.84,0,0,0-24.586,30.052Z"
                                            transform="translate(-20299.977 -1863.493)"
                                            fill="url(#radial-gradient-logo)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g transform="translate(7.526 110.375)">
                            <g clipPath="url(#clip-path-logo)">
                                <rect
                                    width="451.468"
                                    height="37.06"
                                    transform="matrix(0.579, -0.815, 0.815, 0.579, 84.593, 303.645)"
                                    fill="url(#radial-gradient-logo)"
                                />
                                <rect
                                    width="390.933"
                                    height="37.059"
                                    transform="translate(47.028 244.484) rotate(-54.75)"
                                    fill="url(#radial-gradient-logo)"
                                />
                                <rect
                                    width="296.612"
                                    height="37.06"
                                    transform="translate(4.971 192.556) rotate(-54.75)"
                                    fill="url(#radial-gradient-logo)"
                                />
                            </g>
                        </g>
                    </g>
                    <path
                        d="M0,0H625.06V37.058H0Z"
                        transform="matrix(0.588, -0.809, 0.809, 0.588, 584.619, 611.348)"
                        opacity="0.999"
                        fill="url(#radial-gradient-logo)"
                    />
                </g>
            </g>
        </svg>
    );
}

LogoFr.propTypes = propTypes;
LogoFr.defaultProps = defaultProps;

export default LogoFr;

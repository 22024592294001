/* eslint-disable react/jsx-props-no-spreading, react/no-array-index-key */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import tinycolor from 'tinycolor2';

import styles from '../../styles/partials/gradient-circle.module.scss';

const propTypes = {
    color: PropTypes.string,
    played: PropTypes.bool,
    playedColor: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: null,
    played: false,
    playedColor: '#212121',
    className: null,
};

const GradientCircle = ({ color, played, playedColor, className }) => {
    const colors = useMemo(
        () => [
            tinycolor(played ? playedColor : color).toString(),
            tinycolor(played ? playedColor : color)
                .setAlpha(0)
                .toString(),
        ],
        [color, played],
    );

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 100 100"
            aria-hidden
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <defs>
                <radialGradient
                    id={`gradient-normal-${color}`}
                    cx="50%"
                    cy="50%"
                    r="50%"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="75%" stopColor={colors[1]} />
                    <stop offset="100%" stopColor={colors[0]} />
                </radialGradient>
                <radialGradient
                    id={`gradient-hover-${color}`}
                    cx="50%"
                    cy="50%"
                    r="50%"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="25%" stopColor={colors[1]} />
                    <stop offset="100%" stopColor={colors[0]} />
                </radialGradient>
            </defs>
            {!played ? <circle fill="#000" cx="50%" cy="50%" r="50%" /> : null}
            <circle
                fill={`url(#gradient-normal-${color})`}
                cx="50%"
                cy="50%"
                r="50%"
                className={styles.normal}
            />
            <circle
                fill={`url(#gradient-hover-${color})`}
                cx="50%"
                cy="50%"
                r="50%"
                className={styles.hover}
            />
        </svg>
    );
};

GradientCircle.propTypes = propTypes;
GradientCircle.defaultProps = defaultProps;

export default GradientCircle;

import { useUrlGenerator } from '@folklore/routes';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { scenes } from '../data';

function useSceneUrlGenerator() {
    const route = useUrlGenerator();
    const { locale } = useIntl();
    const urlGenerator = useCallback(
        (id, story = null) => {
            const { slug = null } = scenes.find(({ id: sceneId }) => sceneId === id) || {};
            if (slug === null) {
                return route('experience');
            }
            const sceneSlug = slug[locale];
            return story !== null
                ? route('story', {
                      scene: sceneSlug,
                      story,
                  })
                : route('scene', {
                      scene: sceneSlug,
                  });
        },
        [route, locale],
    );
    return urlGenerator;
}

export default useSceneUrlGenerator;

import PropTypes from 'prop-types';
// import raf from 'raf';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

// import tinycolor from 'tinycolor2';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
    endPosition: PropTypes.number,
    onEnd: PropTypes.func,
    duration: PropTypes.number,
};

const defaultProps = {
    color: '#45438e',
    endPosition: 0.7,
    duration: 10000,
    onEnd: null,
    className: null,
};

function LogoEn({ color, endPosition, duration, onEnd, className }) {
    const intl = useIntl();

    // const [currentColor, setCurrentColor] = useState(color);
    const [progress, setProgress] = useState(0);
    // const progressRef = useRef(progress);
    // useEffect(() => {
    //     if (color === currentColor) {
    //         return () => {};
    //     }
    //     let canceled = false;
    //     let currentTime = new Date().getTime();

    //     function updateProgress(newProgress) {
    //         progressRef.current = newProgress;
    //         setProgress(newProgress);
    //         return newProgress;
    //     }

    //     let currentProgress = updateProgress(0);
    //     let handle = raf(function loop() {
    //         if (canceled) {
    //             return;
    //         }
    //         const newTime = new Date().getTime();
    //         const elapsed = newTime - currentTime;
    //         const step = elapsed / duration;
    //         currentProgress = updateProgress(Math.min(currentProgress + step, 1));
    //         currentTime = newTime;
    //         if (currentProgress < 1) {
    //             handle = raf(loop);
    //         } else {
    //             setCurrentColor(color);
    //             setProgress(0);
    //         }
    //     });

    //     return () => {
    //         canceled = true;
    //         if (handle !== null) {
    //             raf.cancel(handle);
    //         }
    //     };
    // }, [color, setProgress]);

    // useEffect(() => {
    //     if (onEnd !== null) {
    //         onEnd(currentColor);
    //     }
    // }, [currentColor]);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnxlink="http://www.w3.org/1999/xlink"
            width="1781.97"
            height="798.482"
            viewBox="0 0 1781.97 798.482"
            aria-label={intl.formatMessage({
                defaultMessage: 'Dream Voices logo',
                description: 'Logo label',
            })}
            className={className}
        >
            <defs>
                <radialGradient
                    id="radial-gradient-logo"
                    cx="0.5"
                    cy="0.5"
                    r="0.5"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor={color} />
                    <stop
                        offset={progress * endPosition}
                        // stopColor={
                        //     currentColor !== color
                        //         ? tinycolor.mix(currentColor, color, progress * 100)
                        //         : currentColor
                        // }
                        stopColor="#d6fd97"
                    />

                    <stop
                        offset={endPosition}
                        // stopColor={
                        //     (color !== currentColor) !== null
                        //         ? tinycolor.mix(
                        //               currentColor,
                        //               color,
                        //               Math.max(0, (progress - 0.5) / 0.5) * 100,
                        //           )
                        //         : currentColor
                        // }
                        stopColor="#819B96"
                    />
                    <stop offset="1" stopColor="#212121" />
                </radialGradient>
                <radialGradient
                    id="radial-gradient-logo-2"
                    gradientTransform="matrix(0, -1, 1.164, 0, -0.082, 1)"
                    xlinkHref="#radial-gradient-logo"
                />
                <clipPath id="clip-path-logo">
                    <rect width="1161.984" height="288.707" fill="url(#radial-gradient-logo)" />
                </clipPath>
                <clipPath id="clip-path-logo-2">
                    <path
                        d="M9.85,274.68a417.318,417.318,0,0,0,40.084,62.478c21.151,27.392,50.092,57.859,86.122,79.556l11.364-16.008c27.9,14.058,51.593,19.14,88.727,19.14,130.887,0,211.036-120.489,225.259-143.672a.438.438,0,0,0,.073-.128q.41-.737.711-1.366C453.3,262.169,401.618,193.637,315.076,164.7a247.766,247.766,0,0,0-79.056-12.929c-134,0-214.842,106.958-226.17,122.911"
                        transform="translate(-9.85 -151.769)"
                        fill="url(#radial-gradient-logo-2)"
                    />
                </clipPath>
            </defs>
            <g transform="translate(-69.017 -142.258)">
                <g transform="translate(379.008 142.258)">
                    <g clip-path-logo="url(#clip-path-logo)">
                        <path
                            d="M203.515,229l1.456,1.821q-6.552,9.472-7.281,10.557Q180.214,266.5,169.11,277.6t-24.574,11.1q-13.841,0-21.482-10.193t-6.552-35.68h-.363v.364q-18.209,20.393-31.31,32.948t-16.383,12.2q-34.952-4.369-51.7-28.58T0,197.325q0-41.861,17.3-66.259t39.137-33.5q21.843-9.094,37.5-9.1a93,93,0,0,1,17.838,1.457V36.771L89.2,28.761V26.577L174.025.729h.363L176.574,0v242.47ZM70.447,224.448Q77.182,247.2,93.2,247.2a26.692,26.692,0,0,0,9.282-2,28.849,28.849,0,0,0,9.285-5.644V129.974q-2.185-17.11-8.556-24.575T88.833,97.935q-11.655,0-18.386,13.289T63.713,159.1q0,42.6,6.734,65.349"
                            transform="translate(0 0.001)"
                            fill="url(#radial-gradient-logo)"
                        />
                        <path
                            d="M328.892,85.777a184.289,184.289,0,0,1-18.2,33.313Q299.4,135.66,286.3,135.656a16.042,16.042,0,0,1-8.921-2.731,59.639,59.639,0,0,1-9.284-7.827,66.361,66.361,0,0,0-8.373-7.282,13.17,13.17,0,0,0-7.646-2.549c-1.217,0-2.73.854-4.551,2.549a16.931,16.931,0,0,0-4.187,6.19V247.424l16.746,13.835v2.184H162.151v-2.184l17.11-14.2V110.9l-27.305,13.47-1.455-1.821,7.644-10.922q12.015-17.108,19.3-26.03A77.347,77.347,0,0,1,193.095,70.85a30.191,30.191,0,0,1,17.475-5.825q13.106,0,20.57,8.919t7.464,31.127q0,6.923-.363,10.559h2.912l9.465-13.107q13.465-18.924,20.934-28.4t11.1-9.467q2.543,0,6.554,3.095t8.01,6.735a116.416,116.416,0,0,0,12.014,10.194q5.094,3.643,9.831,3.641a11.407,11.407,0,0,0,4.55-.728,10.253,10.253,0,0,0,3.458-2.913Z"
                            transform="translate(65.987 19.804)"
                            fill="url(#radial-gradient-logo)"
                        />
                        <path
                            d="M450.8,211.83q-52.065,57.162-69.535,57.159-29.133,0-52.79-12.2a91.1,91.1,0,0,1-37.136-34.586q-13.473-22.39-13.469-52.607,0-38.951,18.931-62.62t42.415-33.13q23.482-9.462,38.41-9.465,32.765,0,50.6,18.02t20.024,47.147q0,4.736-12.378,12.56a158.624,158.624,0,0,1-28.031,13.835q-15.66,6.009-23.3,6.008h-46.6q11.282,25.488,30.946,41.869T414.754,220.2a54.392,54.392,0,0,0,15.837-2.367,111.682,111.682,0,0,0,18.384-7.827ZM332.657,89.5q-5.649,8.378-5.643,24.029,0,19.661,8.375,41.867h47.693a144.224,144.224,0,0,0-5.28-37.681q-4.914-16.929-13.107-26.759t-18.021-9.83q-8.378,0-14.016,8.373"
                            transform="translate(121.604 19.719)"
                            fill="url(#radial-gradient-logo)"
                        />
                        <path
                            d="M610.77,208.189l1.456,1.821-8.01,11.285q-17.115,24.763-28.033,35.68T552.155,267.9q-13.107,0-20.57-9.1t-7.464-31.31q0-6.911.364-10.559h-1.82q-10.563,23.672-25.121,37.5T460.773,268.26q-18.934,0-30.4-10.376t-11.468-28.579q0-22.937,17.111-38.228t46.237-28.76L522.3,144.842q.359-26.94-7.281-43.142T494.632,85.5a15.569,15.569,0,0,0-12.743,5.825q-4.736,5.829-4.733,17.84,0,14.566,6.554,35.315l-67.353,18.932-1.093-1.821q19.294-41.5,44.417-63.166t44.6-27.851q19.471-6.186,28.943-6.189,22.2,0,34.222,7.1a36.043,36.043,0,0,1,16.383,20.024q4.369,12.926,4.369,32.22,0,13.473-1.092,28.4l-3.277,64.441v5.462Zm-101.211,11.1q6.911-4.914,12.014-14.744l.729-51.7q-22.578,15.289-32.221,27.305t-9.648,24.029q0,10.2,5.1,15.108t10.923,4.916q6.186,0,13.106-4.916"
                            transform="translate(183.261 19.718)"
                            fill="url(#radial-gradient-logo)"
                        />
                        <path
                            d="M866.225,247.059l16.746,14.2v2.184H785.038v-2.184l17.111-14.2V132.74q0-23.66-20.024-23.663-12.38,0-21.844,12.743a43.757,43.757,0,0,1,.364,6.554l-.729,118.685,17.111,14.2v2.184H679.094v-2.184l16.746-14.2V132.74q0-23.66-19.659-23.663-12.748,0-21.48,12.378v125.6l16.748,14.2v2.184H573.515v-2.184l17.111-14.2V110.9l-27.306,13.469-1.455-1.82,7.646-10.923Q581.523,94.517,588.8,85.6a77.357,77.357,0,0,1,15.654-14.746,30.2,30.2,0,0,1,17.475-5.825q13.108,0,20.57,9.1t7.464,31.31q0,7.287-.363,11.649h.363Q688.2,64.66,704.942,64.66q24.389,0,37.681,12.926t16.929,34.768q35.677-47.69,51.334-47.693,28.756,0,42.232,17.658t13.47,46.055Z"
                            transform="translate(260.792 19.804)"
                            fill="url(#radial-gradient-logo)"
                        />
                    </g>
                </g>
                <g transform="translate(57.422 -87.812)">
                    <g transform="translate(0 0)">
                        <g transform="translate(0 0)">
                            <path
                                d="M179.486,872.143H314.113v3.543L168.858,1195.128h-6.494L14.747,875.686v-3.543H160v3.543L132.84,899.895l75.58,174.778h3.543l8.266-23.027q8.263-25.382,8.267-56.1,0-37.78-13.286-70.56t-35.724-49.3Z"
                                transform="translate(-3.152 -186.433)"
                                fill="url(#radial-gradient-logo)"
                            />
                            <path
                                d="M1011.236,1123.9l.591-259.215-27.162-24.8v-3.543h131.673v28.342l-1.181,259.215,27.162,23.028v3.543H983.484v-3.543Zm13.286-336.86q-14.477-13.287-14.467-35.133,0-21.257,14.467-34.247t38.085-12.99q22.434,0,36.313,12.99t13.876,34.247q0,21.852-13.876,35.133t-36.313,13.285q-23.624,0-38.085-13.285"
                                transform="translate(-210.23 -150.633)"
                                fill="url(#radial-gradient-logo)"
                            />
                            <path
                                d="M1286.078,1173.236a142.261,142.261,0,0,1-59.047-56.1q-21.256-36.6-21.257-86.8,0-62.582,30.115-100.379Q1266,892.183,1303.5,876.526q37.49-15.638,61.7-15.647,33.656,0,58.751,11.218t38.971,25.981q13.867,14.767,17.418,25.39l-98.608,83.847-2.952-1.771q-5.314-46.056-24.5-81.78t-42.218-35.723q-14.172,0-22.733,11.514t-8.562,35.133q0,34.847,18.895,76.761a205.473,205.473,0,0,0,52.257,71.151q33.352,29.228,74.694,29.228,25.977,0,57.276-16.534l2.952,3.544q-38.39,42.513-69.675,68.2-31.3,25.687-46.056,25.685-47.248,0-85.027-19.484"
                                transform="translate(-257.746 -184.025)"
                                fill="url(#radial-gradient-logo)"
                            />
                            <path
                                d="M1851.226,1100.018q-84.444,92.707-112.778,92.7-47.247,0-85.618-19.78a147.768,147.768,0,0,1-60.228-56.094q-21.851-36.314-21.847-85.322,0-63.176,30.7-101.56t68.791-53.733q38.085-15.347,62.294-15.351,53.141,0,82.074,29.228t32.476,76.465q0,7.681-20.077,20.371a257.316,257.316,0,0,1-45.465,22.438q-25.4,9.743-37.791,9.742h-75.579q18.293,41.338,50.189,67.9t74.4,26.571a88.19,88.19,0,0,0,25.686-3.838q12.69-3.833,29.818-12.7Zm-191.606-198.4q-9.161,13.589-9.152,38.97,0,31.886,13.581,67.9H1741.4q-.594-33.657-8.562-61.114t-21.256-43.4q-13.286-15.944-29.229-15.943-13.588,0-22.733,13.581"
                                transform="translate(-335.765 -184.025)"
                                fill="url(#radial-gradient-logo)"
                            />
                            <path
                                d="M2132.617,990.408q27.746,18.9,42.808,31.59a116.5,116.5,0,0,1,25.1,29.523q10.033,16.828,10.038,39.266,0,23.624-11.809,46.352t-40.152,38.085q-28.343,15.347-73.808,15.352a213.653,213.653,0,0,1-29.229-1.771q-12.7-1.771-31.59-5.9-4.733-.581-17.123-2.952a129.769,129.769,0,0,0-24.209-2.362,119.263,119.263,0,0,0-19.485,1.771l-12.4-164.74h3.543a344.771,344.771,0,0,0,57.275,80.009q34.238,35.732,68.2,55.8t56.981,20.076q8.262,0,13.581-4.428t5.314-13.876q0-10.033-12.1-22.143t-45.171-33.361l-69.084-46.056q-33.075-21.839-49.009-43.4t-15.943-49.894q0-44.284,32.476-71.152t84.437-26.866q47.232,0,82.665,20.077l27.752-23.029h3.542v151.75h-4.133q-27.166-43.095-56.094-72.333t-54.028-43.4q-25.1-14.171-41.627-14.17-19.485,0-19.485,15.942,0,10.047,9.152,19.781t28.638,22.733Z"
                                transform="translate(-416.993 -183.061)"
                                fill="url(#radial-gradient-logo)"
                            />
                        </g>
                    </g>
                </g>
                <g transform="translate(-124.023 420.365)">
                    <g transform="translate(478.023 142.351)">
                        <g transform="translate(0 0)">
                            <g>
                                <path
                                    d="M471.751,237.157s-.464.97-1.393,2.765c-11.554-16.039-94.03-120.379-230.693-120.379-136.682,0-219.14,104.34-230.694,120.379-.929-1.795-1.393-2.765-1.393-2.765S91.967,62.309,239.664,62.309c147.678,0,232.087,174.848,232.087,174.848"
                                    transform="translate(-7.577 -57.756)"
                                    fill="url(#radial-gradient-logo-2)"
                                />
                                <path
                                    d="M458.257,240.49a4.551,4.551,0,0,1-3.712-1.918c-6.024-8.481-28.588-38.34-66.105-66.428-48.36-36.207-100.966-54.564-156.353-54.564s-108,18.357-156.358,54.564c-37.5,28.081-60.076,57.945-66.1,66.428a4.553,4.553,0,0,1-7.767-.565C.91,236.14.433,235.13.433,235.13a4.567,4.567,0,0,1,.007-3.9c.857-1.8,21.41-44.564,60.182-88.027C112.255,85.324,171.547,54.73,232.086,54.73S351.91,85.324,403.545,143.2c38.774,43.463,59.332,86.227,60.189,88.027a4.567,4.567,0,0,1,.007,3.9s-.477,1.01-1.43,2.878a4.553,4.553,0,0,1-3.725,2.47c-.11.009-.222.013-.331.013M232.086,63.836c-73.035,0-129.775,46.363-164.516,85.258a419.663,419.663,0,0,0-41.5,55.419,340.652,340.652,0,0,1,44.2-39.659c49.968-37.41,104.411-56.38,161.816-56.38s111.839,18.97,161.809,56.38A340.558,340.558,0,0,1,438.1,204.51,419.463,419.463,0,0,0,396.6,149.093C361.854,110.2,305.112,63.836,232.086,63.836"
                                    transform="translate(0 -54.73)"
                                    fill="url(#radial-gradient-logo-2)"
                                />
                                <path
                                    d="M633.739,358.849"
                                    transform="translate(-176.266 -176.147)"
                                    fill="url(#radial-gradient-logo-2)"
                                />
                                <path
                                    d="M453.764,271.957c-12.736-17.3-62.581-79.024-143.158-105.966A243.949,243.949,0,0,0,232.993,153.3c-55.394,0-108,18.357-156.358,54.563-37.5,28.081-60.075,57.945-66.1,66.429a4.552,4.552,0,1,1-7.423-5.272c6.217-8.757,29.5-39.572,68.064-68.447,49.968-37.411,104.41-56.38,161.816-56.38a253.066,253.066,0,0,1,80.5,13.166C400.947,186.6,453.273,255.5,462.875,269.015a4.554,4.554,0,0,1,.395,4.6c-.226.47-.487.978-.789,1.525-.066.127-.141.256-.22.382l0,0"
                                    transform="translate(-0.907 -90.446)"
                                    fill="url(#radial-gradient-logo-2)"
                                />
                                <path
                                    d="M407.137,308.456c0,36.02-27.784,65.218-62.056,65.218s-62.057-29.2-62.057-65.218,27.784-65.219,62.057-65.219h0c34.272,0,62.055,29.2,62.055,65.219"
                                    transform="translate(-112.998 -129.99)"
                                    fill="url(#radial-gradient-logo-2)"
                                />
                                <path
                                    d="M233.519,566.492a4.542,4.542,0,0,1-2-.468c-.962-.472-1.926-.972-2.9-1.476l-.642-.331a4.553,4.553,0,1,1,4.176-8.091l.649.335c.918.476,1.825.945,2.732,1.39a4.554,4.554,0,0,1-2.011,8.641"
                                    transform="translate(-90.034 -254.705)"
                                    fill="url(#radial-gradient-logo-2)"
                                />
                                <g transform="translate(464.177 365.058) rotate(180)">
                                    <g transform="translate(0 0)">
                                        <path
                                            d="M1.815,183.183C.9,181.376.434,180.4.434,180.4A4.666,4.666,0,0,1,0,178.5v-.078A4.588,4.588,0,0,1,.441,176.5c.858-1.8,21.411-44.564,60.182-88.027C112.258,30.6,171.548,0,232.088,0c32.8,0,65.233,8.984,96.281,26.42l22.569,15.645-45.161,61.762a373.213,373.213,0,0,0-73.69-7.229c-98.334,0-168.034,36.97-202.655,62.956A271.763,271.763,0,0,0,10.1,183.183Z"
                                            fill="url(#radial-gradient-logo-2)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g transform="translate(5.917 58.297)">
                            <g clip-path-logo="url(#clip-path-logo-2)">
                                <rect
                                    width="354.96"
                                    height="29.138"
                                    transform="matrix(0.579, -0.815, 0.815, 0.579, 66.51, 238.736)"
                                    fill="url(#radial-gradient-logo-2)"
                                />
                                <rect
                                    width="307.365"
                                    height="29.137"
                                    transform="translate(36.975 192.222) rotate(-54.75)"
                                    fill="url(#radial-gradient-logo-2)"
                                />
                                <rect
                                    width="233.206"
                                    height="29.138"
                                    transform="translate(3.908 151.394) rotate(-54.75)"
                                    fill="url(#radial-gradient-logo-2)"
                                />
                            </g>
                        </g>
                    </g>
                    <path
                        d="M0,0H491.443V29.136H0Z"
                        transform="matrix(0.588, -0.809, 0.809, 0.588, 561.833, 503.249)"
                        opacity="0.999"
                        fill="url(#radial-gradient-logo-2)"
                    />
                </g>
                <g transform="translate(-121.023 420.365)">
                    <g transform="translate(478.023 142.351)">
                        <g transform="translate(0 0)">
                            <g>
                                <path
                                    d="M471.751,237.157s-.464.97-1.393,2.765c-11.554-16.039-94.03-120.379-230.693-120.379-136.682,0-219.14,104.34-230.694,120.379-.929-1.795-1.393-2.765-1.393-2.765S91.967,62.309,239.664,62.309c147.678,0,232.087,174.848,232.087,174.848"
                                    transform="translate(-7.577 -57.756)"
                                    fill="url(#radial-gradient-logo-2)"
                                />
                                <path
                                    d="M458.257,240.49a4.551,4.551,0,0,1-3.712-1.918c-6.024-8.481-28.588-38.34-66.105-66.428-48.36-36.207-100.966-54.564-156.353-54.564s-108,18.357-156.358,54.564c-37.5,28.081-60.076,57.945-66.1,66.428a4.553,4.553,0,0,1-7.767-.565C.91,236.14.433,235.13.433,235.13a4.567,4.567,0,0,1,.007-3.9c.857-1.8,21.41-44.564,60.182-88.027C112.255,85.324,171.547,54.73,232.086,54.73S351.91,85.324,403.545,143.2c38.774,43.463,59.332,86.227,60.189,88.027a4.567,4.567,0,0,1,.007,3.9s-.477,1.01-1.43,2.878a4.553,4.553,0,0,1-3.725,2.47c-.11.009-.222.013-.331.013M232.086,63.836c-73.035,0-129.775,46.363-164.516,85.258a419.663,419.663,0,0,0-41.5,55.419,340.652,340.652,0,0,1,44.2-39.659c49.968-37.41,104.411-56.38,161.816-56.38s111.839,18.97,161.809,56.38A340.558,340.558,0,0,1,438.1,204.51,419.463,419.463,0,0,0,396.6,149.093C361.854,110.2,305.112,63.836,232.086,63.836"
                                    transform="translate(0 -54.73)"
                                    fill="url(#radial-gradient-logo-2)"
                                />
                                <path
                                    d="M633.739,358.849"
                                    transform="translate(-176.266 -176.147)"
                                    fill="url(#radial-gradient-logo-2)"
                                />
                                <path
                                    d="M453.764,271.957c-12.736-17.3-62.581-79.024-143.158-105.966A243.949,243.949,0,0,0,232.993,153.3c-55.394,0-108,18.357-156.358,54.563-37.5,28.081-60.075,57.945-66.1,66.429a4.552,4.552,0,1,1-7.423-5.272c6.217-8.757,29.5-39.572,68.064-68.447,49.968-37.411,104.41-56.38,161.816-56.38a253.066,253.066,0,0,1,80.5,13.166C400.947,186.6,453.273,255.5,462.875,269.015a4.554,4.554,0,0,1,.395,4.6c-.226.47-.487.978-.789,1.525-.066.127-.141.256-.22.382l0,0"
                                    transform="translate(-0.907 -90.446)"
                                    fill="url(#radial-gradient-logo-2)"
                                />
                                <path
                                    d="M407.137,308.456c0,36.02-27.784,65.218-62.056,65.218s-62.057-29.2-62.057-65.218,27.784-65.219,62.057-65.219h0c34.272,0,62.055,29.2,62.055,65.219"
                                    transform="translate(-112.998 -129.99)"
                                    fill="url(#radial-gradient-logo-2)"
                                />
                                <path
                                    d="M233.519,566.492a4.542,4.542,0,0,1-2-.468c-.962-.472-1.926-.972-2.9-1.476l-.642-.331a4.553,4.553,0,1,1,4.176-8.091l.649.335c.918.476,1.825.945,2.732,1.39a4.554,4.554,0,0,1-2.011,8.641"
                                    transform="translate(-90.034 -254.705)"
                                    fill="url(#radial-gradient-logo-2)"
                                />
                                <g transform="translate(464.177 365.058) rotate(180)">
                                    <g transform="translate(0 0)">
                                        <path
                                            d="M1.815,183.183C.9,181.376.434,180.4.434,180.4A4.666,4.666,0,0,1,0,178.5v-.078A4.588,4.588,0,0,1,.441,176.5c.858-1.8,21.411-44.564,60.182-88.027C112.258,30.6,171.548,0,232.088,0c32.8,0,65.233,8.984,96.281,26.42l22.569,15.645-45.161,61.762a373.213,373.213,0,0,0-73.69-7.229c-98.334,0-168.034,36.97-202.655,62.956A271.763,271.763,0,0,0,10.1,183.183Z"
                                            fill="url(#radial-gradient-logo-2)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                        <g transform="translate(5.917 58.297)">
                            <g clipPath="url(#clip-path-logo-2)">
                                <rect
                                    width="354.96"
                                    height="29.138"
                                    transform="matrix(0.579, -0.815, 0.815, 0.579, 66.51, 238.736)"
                                    fill="url(#radial-gradient-logo-2)"
                                />
                                <rect
                                    width="307.365"
                                    height="29.137"
                                    transform="translate(36.975 192.222) rotate(-54.75)"
                                    fill="url(#radial-gradient-logo-2)"
                                />
                                <rect
                                    width="233.206"
                                    height="29.138"
                                    transform="translate(3.908 151.394) rotate(-54.75)"
                                    fill="url(#radial-gradient-logo-2)"
                                />
                            </g>
                        </g>
                    </g>
                    <path
                        d="M0,0H491.443V29.136H0Z"
                        transform="matrix(0.588, -0.809, 0.809, 0.588, 561.833, 503.249)"
                        opacity="0.999"
                        fill="url(#radial-gradient-logo-2)"
                    />
                </g>
            </g>
        </svg>
    );
}

LogoEn.propTypes = propTypes;
LogoEn.defaultProps = defaultProps;

export default LogoEn;

import * as enterAudios from './audio/enters';
import * as audios from './audio/index';
import * as textAudios from './audio/texts';
import * as images from './images/index';
import * as englishSubtitles from './subtitles/en';
import * as frenchSubtitles from './subtitles/fr';
import * as englishSubtitlesText from './subtitles/markdown/en';
import * as frenchSubtitlesText from './subtitles/markdown/fr';
import * as texts from './texts/index';

const routes = {
    default: {
        home: '/',
        intro: '/intro',
        instructions: '/instructions',
        participants: '/participants',
        participant: '/participants/:participant',
        story: '/participants/:participant/:story',
        outro: '/outro',
        about: '/about',
        credits: '/credits',
    },
    fr: {},
    en: {},
};

const scenes = [
    {
        id: 'intro',
        // slug: {
        //     fr: '',
        //     en: '',
        // },
    },
    {
        id: 'characters',
        slug: {
            fr: 'participants',
            en: 'participants',
        },
    },
    {
        id: 'menu',
        slug: {
            fr: 'menu',
            en: 'menu',
        },
    },
    {
        id: 'profile',
        slug: {
            fr: 'profil',
            en: 'profile',
        },
    },
    {
        id: 'story_text',
        slug: {
            fr: 'texte',
            en: 'text',
        },
        component: 'story',
    },
    {
        id: 'story_audio',
        slug: {
            fr: 'audio',
            en: 'audio',
        },
        component: 'story',
    },
    {
        id: 'outro',
        slug: {
            fr: 'fin',
            en: 'outro',
        },
    },
];

const stories = [
    {
        id: 'selma',
        audioEnter: enterAudios.selma,
        audio: audios.selma,
        audioText: textAudios.selma,
        text: texts.selma,
        color: '#9EA778',
        name: 'Selma Kouidri',
        images: images.selma,
        subtitles: {
            en: englishSubtitles.selma,
            fr: frenchSubtitles.selma,
            transcription: {
                en: englishSubtitlesText.selma,
                fr: frenchSubtitlesText.selma,
            },
        },
        metadata: {
            en: [
                {
                    label: 'Description of her level of vision',
                    value: 'Totally blind in the left eye. Vision in the right eye impaired by continual blinding flashes.',
                },
                {
                    label: 'Age',
                    value: '52',
                },
                {
                    label: 'Profession',
                    value: 'Director and Co-founder of the National Institute for equity, equality and inclusion for persons with disabilities (INÉÉI-PSH, <em>Institut national pour l’équité, l’égalité et l’inclusion des personnes en situation de handicap</em>)',
                },
                {
                    label: 'Interests',
                    value: 'Domestic and international politics, reading',
                },
                {
                    label: 'Message she would like to share concerning the condition of blind and visually impaired people',
                    value: 'The visually impaired and the blind form a community that is populous and highly diverse, yet they remain quite invisible to a society that claims to be fair-minded and inclusive!',
                },
            ],
            fr: [
                {
                    label: 'Vision',
                    value: 'Cécité totale de l’œil gauche. Vision instable dans l’œil droit causée par des éblouissements constants.',
                },
                {
                    label: 'Âge',
                    value: '52 ans',
                },
                {
                    label: 'Profession',
                    value: 'Directrice et cofondatrice de l’Institut national pour l’équité, l’égalité et l’inclusion des personnes en situation de handicap (INÉÉI-PSH)',
                },
                {
                    label: 'Intérêts',
                    value: 'Politique interne et internationale, lecture',
                },
                {
                    label: 'Ce qu’elle aimerait dire à propos de la condition des personnes malvoyantes ou aveugles',
                    value: 'Les personnes aveugles et malvoyantes sont diverses, plurielles, mais toutes invisibles aux yeux d’une société qui se dit pourtant équitable et inclusive!',
                },
            ],
        },
    },
    {
        id: 'francois',
        audio: audios.francois,
        audioText: textAudios.francois,
        audioEnter: enterAudios.francois,
        text: texts.francois,
        color: '#45438E',
        name: 'François Côté',
        images: images.francois,
        subtitles: {
            en: englishSubtitles.francois,
            fr: frenchSubtitles.francois,
            transcription: {
                en: englishSubtitlesText.francois,
                fr: frenchSubtitlesText.francois,
            },
        },
        metadata: {
            en: [
                {
                    label: 'Description of his level of vision',
                    value: 'Total blindness',
                },
                {
                    label: 'Age',
                    value: '64',
                },
                {
                    label: 'Profession',
                    value: 'Psychologist',
                },
                {
                    label: 'Interests',
                    value: 'the outdoors, the guitar, reading, travel',
                },
                {
                    label: 'Message he would like to share concerning the condition of blind and visually impaired people',
                    value: 'A visual handicap only serves to underscore the rich interdependence among us as human beings.',
                },
            ],
            fr: [
                {
                    label: 'Vision',
                    value: 'Cécité totale',
                },
                {
                    label: 'Âge',
                    value: '64 ans',
                },
                {
                    label: 'Profession',
                    value: 'Psychologue',
                },
                {
                    label: 'Intérêts',
                    value: 'Plein air, guitare, lecture, voyages',
                },
                {
                    label: 'Ce qu’il aimerait dire à propos de la condition des personnes malvoyantes ou aveugles',
                    value: 'Un handicap visuel ne fait que souligner la richesse de l’interdépendance des êtres les uns envers les autres.',
                },
            ],
        },
    },
    {
        id: 'anne',
        audio: audios.anne,
        audioText: textAudios.anne,
        audioEnter: enterAudios.anne,
        text: texts.anne,
        color: '#4A6895',
        name: 'Anne Jarry',
        images: images.anne,
        subtitles: {
            en: englishSubtitles.anne,
            fr: frenchSubtitles.anne,
            transcription: {
                en: englishSubtitlesText.anne,
                fr: frenchSubtitlesText.anne,
            },
        },
        metadata: {
            en: [
                {
                    label: 'Description of her level of vision',
                    value: 'Totally blind in the left eye, low peripheral vision in the right eye',
                },
                {
                    label: 'Age',
                    value: '59',
                },
                {
                    label: 'Profession',
                    value: ' Adult educator, specialist in visual impairment rehabilitation',
                },
                {
                    label: 'Interests',
                    value: 'Reading, spanish, the guitar',
                },
                {
                    label: 'Message she would like to share concerning the condition of blind and visually impaired people',
                    value: 'Blind and visually impaired people are all unique.',
                },
            ],
            fr: [
                {
                    label: 'Vision',
                    value: 'cécité totale de l’œil gauche, mince vision périphérique dans l’œil droit',
                },
                {
                    label: 'Âge',
                    value: '59 ans',
                },
                {
                    label: 'Profession',
                    value: 'Andragogue, spécialiste en réadaptation en déficience visuelle',
                },
                {
                    label: 'Intérêts',
                    value: 'Lecture, espagnol, guitare',
                },
                {
                    label: 'Ce qu’elle aimerait dire à propos de la condition des personnes malvoyantes ou aveugles',
                    value: 'Les personnes aveugles et malvoyantes sont toutes uniques.',
                },
            ],
        },
    },
    {
        id: 'josue',
        audio: audios.josue,
        audioText: textAudios.josue,
        audioEnter: enterAudios.josue,
        text: texts.josue,
        color: '#8C5D74',
        name: 'Josué Coudé',
        images: images.josue,
        subtitles: {
            en: englishSubtitles.josue,
            fr: frenchSubtitles.josue,
            transcription: {
                en: englishSubtitlesText.josue,
                fr: frenchSubtitlesText.josue,
            },
        },
        metadata: {
            en: [
                {
                    label: 'Description of his level of vision',
                    value: 'Total blindness',
                },
                {
                    label: 'Age',
                    value: '31',
                },
                {
                    label: 'Profession',
                    value: 'Visual rehabilitation specialist',
                },
                {
                    label: 'Interests',
                    value: 'Cycling, chess, reading',
                },
                {
                    label: 'Message he would like to share concerning the condition of blind and visually impaired people',
                    value: 'You can’t see properly without the eyes of the heart.',
                },
            ],
            fr: [
                {
                    label: 'Vision',
                    value: 'Cécité totale',
                },
                {
                    label: 'Âge',
                    value: '31 ans',
                },
                {
                    label: 'Profession',
                    value: 'Spécialiste en réadaptation visuelle',
                },
                {
                    label: 'Intérêts',
                    value: 'Vélo, échecs, lecture',
                },
                {
                    label: 'Ce qu’il aimerait dire à propos de la condition des personnes malvoyantes ou aveugles',
                    value: 'On ne voit bien qu’avec les yeux du cœur.',
                },
            ],
        },
    },
    {
        id: 'mariechristine',
        audio: audios.mariechristine,
        audioText: textAudios.mariechristine,
        audioEnter: enterAudios.mariechristine,
        text: texts.mariechristine,
        color: '#A77F78',
        name: 'Marie-Christine Ricignuolo',
        images: images.mariechristine,
        subtitles: {
            en: englishSubtitles.mariechristine,
            fr: frenchSubtitles.mariechristine,
            transcription: {
                en: englishSubtitlesText.mariechristine,
                fr: frenchSubtitlesText.mariechristine,
            },
        },
        metadata: {
            en: [
                {
                    label: 'Description of her level of vision',
                    value: 'Total blindness',
                },
                {
                    label: 'Age',
                    value: '34',
                },
                {
                    label: 'Profession',
                    value: 'Commentator/animator/researcher',
                },
                {
                    label: 'Interests',
                    value: 'Philosophy, humour, podcasts',
                },
                {
                    label: 'Message she would like to share concerning the condition of blind and visually impaired people',
                    value: 'Flowers grow again, even in a field of ashes.',
                },
            ],
            fr: [
                {
                    label: 'Vision',
                    value: 'Cécité totale',
                },
                {
                    label: 'Âge',
                    value: '34 ans',
                },
                {
                    label: 'Profession',
                    value: 'Chroniqueuse/animatrice/recherchiste',
                },
                {
                    label: 'Intérêts',
                    value: 'Philosophie, humour, balados',
                },
                {
                    label: 'Ce qu’elle aimerait dire à propos de la condition des personnes malvoyantes ou aveugles',
                    value: 'Des fleurs repoussent même sur un terrain de cendres.',
                },
            ],
        },
    },
    {
        id: 'nathalie',
        audio: audios.nathalie,
        audioText: textAudios.nathalie,
        audioEnter: enterAudios.nathalie,
        text: texts.nathalie,
        color: '#9D8D58',
        name: 'Nathalie Chartrand',
        images: images.nathalie,
        subtitles: {
            en: englishSubtitles.nathalie,
            fr: frenchSubtitles.nathalie,
            transcription: {
                en: englishSubtitlesText.nathalie,
                fr: frenchSubtitlesText.nathalie,
            },
        },
        metadata: {
            en: [
                {
                    label: 'Description of her level of vision',
                    value: 'Total blindness',
                },
                {
                    label: 'Age',
                    value: '55',
                },
                {
                    label: 'Profession',
                    value: 'massage therapist, Director, Association sportive des aveugles du Québec',
                },
                {
                    label: 'Interests',
                    value: 'The outdoors, cooking, reading, the guitar',
                },
                {
                    label: 'Message she would like to share concerning the condition of blind and visually impaired people',
                    value: 'Independence is living!',
                },
            ],
            fr: [
                {
                    label: 'Vision',
                    value: 'Cécité totale',
                },
                {
                    label: 'Âge',
                    value: '55 ans',
                },
                {
                    label: 'Profession',
                    value: 'Massothérapeute, directrice de l’Association sportive des aveugles du Québec.',
                },
                {
                    label: 'Intérêts',
                    value: 'Plein air, cuisine, lecture, guitare',
                },
                {
                    label: 'Ce qu’elle aimerait dire à propos de la condition des personnes malvoyantes ou aveugles',
                    value: 'L’autonomie, c’est la vie!',
                },
            ],
        },
    },
    {
        id: 'sherri',
        audio: audios.sherri,
        audioText: textAudios.sherri,
        audioEnter: enterAudios.sherri,
        text: texts.sherri,
        color: '#89867C',
        name: 'Sherri Wallace',
        images: images.sherri,
        subtitles: {
            en: englishSubtitles.sherri,
            fr: frenchSubtitles.sherri,
            transcription: {
                en: englishSubtitlesText.sherri,
                fr: frenchSubtitlesText.sherri,
            },
        },
        metadata: {
            en: [
                {
                    label: 'Description of her level of vision',
                    value: 'Total blindness',
                },
                {
                    label: 'Age',
                    value: '59',
                },
                {
                    label: 'Profession',
                    value: 'Assessment of public spaces and evaluate accessibility',
                },
                {
                    label: 'Interests',
                    value: 'The outdoors, health-related issues',
                },
            ],
            fr: [
                {
                    label: 'Vision',
                    value: 'Cécité totale',
                },
                {
                    label: 'Âge',
                    value: '59 ans',
                },
                {
                    label: 'Profession',
                    value: 'Évaluatrice de places publiques en fonction de leur accessibilité',
                },
                {
                    label: 'Intérêts',
                    value: 'Plein air, problématiques entourant la santé',
                },
            ],
        },
    },
    {
        id: 'stephane',
        audio: audios.stephane,
        audioText: textAudios.stephane,
        audioEnter: enterAudios.stephane,
        text: texts.stephane,
        color: '#819B96',
        name: 'Stéphane Frigon',
        images: images.stephane,
        subtitles: {
            en: englishSubtitles.stephane,
            fr: frenchSubtitles.stephane,
            transcription: {
                en: englishSubtitlesText.stephane,
                fr: frenchSubtitlesText.stephane,
            },
        },
        metadata: {
            en: [
                {
                    label: 'Description of his level of vision',
                    value: 'Total blindness',
                },
                {
                    label: 'Age',
                    value: '55',
                },
                {
                    label: 'Profession',
                    value: 'Psychologist',
                },
                {
                    label: 'Interests',
                    value: 'Physical training, music, reading',
                },
            ],
            fr: [
                {
                    label: 'Vision',
                    value: 'Cécité totale',
                },
                {
                    label: 'Âge',
                    value: '55 ans',
                },
                {
                    label: 'Profession',
                    value: 'Psychologue',
                },
                {
                    label: 'Intérêts',
                    value: 'Conditionnement physique, musique, lecture',
                },
            ],
        },
    },
];

const instructions = {
    text: texts.instructions,
};

const about = {
    text: texts.about,
    artists: [
        {
            id: 'rejane',
            name: 'Réjane Bougé',
            role: { en: 'Author', fr: 'Écrivaine' },
            photo: images.rejane[0],
            website: [
                {
                    link: 'http://www.litterature.org/recherche/ecrivains/bouge-rejane-92',
                    text: { en: 'Website', fr: 'Site Web' },
                },
            ],
            bio: {
                en: texts.rejane.en,
                fr: texts.rejane.fr,
            },
        },
        {
            id: 'chantal',
            name: 'Chantal Dumas',
            role: { en: 'Sound Artist', fr: 'Artiste Sonore' },
            photo: images.chantal[1],
            website: [
                {
                    link: 'https://chantaldumas.com',
                    text: { en: 'Website', fr: 'Site Web' },
                },
                {
                    link: 'https://avatarquebec.org/dialoguesavecchantaldumas/',
                    text: {
                        en: 'Dialogues avec Chantal Dumas',
                        fr: 'Dialogues avec Chantal Dumas',
                    },
                },
            ],
            bio: {
                en: texts.chantal.en,
                fr: texts.chantal.fr,
            },
            reference: {
                en: texts.chantal.ref.en,
                fr: texts.chantal.ref.fr,
            },
        },
        {
            id: 'dominic',
            name: 'Dominic Morissette',
            role: { en: 'Photographer', fr: 'Photographe' },
            website: [
                {
                    link: 'www.dominicmorissette.ca',
                    text: { en: 'Website', fr: 'Site Web' },
                },
            ],
            bio: {
                en: texts.dominic.en,
                fr: texts.dominic.fr,
            },
        },
    ],
};

const credits = [
    {
        id: 'main',
        values: [
            {
                label: { en: 'Writer', fr: 'Écrivaine' },
                value: 'Réjane Bougé',
                link: 'https://www.litterature.org/recherche/ecrivains/bouge-rejane-92',
            },
            {
                label: { en: 'Sound Artist', fr: 'Artiste Sonore' },
                value: 'Chantal Dumas',
                link: 'https://chantaldumas.com',
            },
            {
                label: { en: 'Photographer', fr: 'Photographe' },
                value: 'Dominic Morissette',
                link: 'http://www.dominicmorissette.ca',
            },
        ],
    },
    {
        id: 'folklore',
        heading: { en: 'Folklore', fr: 'Folklore', link: 'https://folkloreinc.ca/' },
        values: [
            {
                label: { en: 'Experience Design', fr: 'Conception interactive' },
                value: 'Marc-Antoine Jacques, David Mongeau-Petitpas, Cynthia Arseneault, Joseph Blais, Corentin Guérin',
            },
            {
                label: { en: 'Artistic Director', fr: 'Directeur artistique' },
                value: 'Marc-Antoine Jacques',
            },
            {
                label: {
                    en: 'Technical Director & Web Developer',
                    fr: 'Directeur technique & developpeur web',
                },
                value: 'David Mongeau-Petitpas',
            },
            {
                label: { en: 'Interactive Designer', fr: 'Designer Interactif' },
                value: 'Cynthia Arseneault',
            },
            { label: { en: 'Web Developer', fr: 'Developpeur web' }, value: 'Joseph Blais' },
            {
                label: { en: 'Project Manager', fr: 'Chargé de projet' },
                value: 'Corentin Guérin',
            },
        ],
    },
    {
        id: 'turbine',
        heading: {
            en: 'Turbine Centre Team',
            fr: 'Équipe du centre Turbine',
            link: 'https://centreturbine.org/',
        },
        values: [
            {
                label: { en: 'Production director', fr: 'Direction de production' },
                value: 'Yves Amyot',
            },
            { label: { en: 'Project manager', fr: 'Chargé de projet' }, value: 'Sophie Bisson' },
            { label: { en: 'Project manager', fr: 'Chargé de projet' }, value: 'Martin Bonnard' },
        ],
    },
    {
        id: 'ludociels',
        heading: { en: 'Ludociels', fr: 'Ludociels', link: 'https://www.ludocielspourtous.org/' },
        values: [
            {
                label: {
                    en: 'Accessibility Consultant and Usability Testing',
                    fr: 'Consultant accessibilité et réalisation des tests d’utilisabilité',
                },
                value: 'Thomas Gaudy',
            },
        ],
    },
    {
        id: 'texts',
        heading: { en: 'Texts', fr: 'Textes' },
        values: [
            {
                label: { en: 'Translation', fr: 'Traduction' },
                value: 'Warren MacDonald',
            },
            {
                label: { en: 'Editing', fr: 'Révision' },
                value: 'Le Bruit des Plumes',
                link: 'https://www.lebruitdesplumes.com/',
            },
            {
                label: { en: 'Subtitling', fr: 'Sous-titrage' },
                value: 'difuze',
                link: 'https://www.difuze.com/',
            },
        ],
    },
    {
        id: 'narration',
        heading: { en: 'Voice Narration', fr: 'Narration de voix' },
        values: [{ value: 'Caroline Lavigne' }, { value: 'Antoine Rivard-Nolin' }],
    },
    {
        id: 'agency',
        heading: {
            en: 'Agence artistique Corinne Giguère',
            fr: 'Agence artistique Corinne Giguère',
        },
        values: [
            {
                label: {
                    en: 'Coordinator: Advertising | Media | Variety',
                    fr: 'Coordonnatrice: Publicité | Médias | Variétés',
                },
                value: 'Geneviève Gaboury',
            },
            { label: { en: 'Agent', fr: 'Agente' }, value: 'Jessica Leboeuf' },
        ],
    },
    {
        id: 'sonore',
        heading: {
            en: 'Virage Sonore Studio',
            fr: 'Studio Virage Sonore',
        },
        values: [
            {
                label: {
                    en: 'Producer, sound artist, and composer',
                    fr: 'Réalisateur, artiste sonore et compositeur',
                },
                value: 'Kevin Gironnay',
            },
        ],
    },
    {
        id: 'accessibility',
        heading: {
            en: 'Web accessibility consulting services',
            fr: 'Service conseil en accessibilité web',
        },
        values: [
            {
                label: {
                    en: null,
                    fr: null,
                },
                value: 'CIAO',
            },
        ],
    },
];

const acknowledgements = [
    'Regroupement des aveugles et ambylopes du Montréal métropolitain (RAAMM)',
    'Anne Jarry',
    'Maud Mayo-Rothenbühler (Danse-cité)',
    'Carole Zabihaylo',
    'Anne-Marie',
    'Michel Anthony Borde',
    'Najla Noori',
    'Christina Pessiridis',
    'Simon Boisvert',
];

export { routes, scenes, credits, audios, stories, about, instructions, acknowledgements };

/* eslint-disable jsx-a11y/control-has-associated-label, react/no-danger, react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

import useSwipe from '../../hooks/useSwipe';
import * as AppPropTypes from '../../lib/PropTypes';

import Arrow from '../buttons/Arrow';

import styles from '../../styles/partials/gallery-viewer.module.scss';

const propTypes = {
    images: AppPropTypes.images.isRequired,
    image: AppPropTypes.image,
    focusable: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    focusable: true,
    image: null,
    className: null,
};

const GalleryViewer = ({ images, focusable, image: providedImage, className }) => {
    const intl = useIntl();
    const { locale } = useIntl();

    const [currentImage, setCurrentImage] = useState(providedImage);
    const currentIndex = currentImage !== null ? images.findIndex((it) => it === currentImage) : 0;

    const nextSlide = useCallback(() => {
        const nextIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
        setCurrentImage(images[nextIndex]);
    }, [images, currentIndex, setCurrentImage]);

    const previousSlide = useCallback(() => {
        const nextIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
        setCurrentImage(images[nextIndex]);
    }, [images, currentIndex, setCurrentImage]);

    useEffect(() => {
        if (providedImage !== currentImage) {
            setCurrentImage(providedImage);
        }
    }, [providedImage]);

    const bindSwipe = useSwipe({
        onSwipeRight: previousSlide,
        onSwipeLeft: nextSlide,
    });

    return (
        <section
            aria-label={intl.formatMessage(
                {
                    defaultMessage: 'Carousel with {imageslength} images',
                    description: 'Logo label',
                },
                { imageslength: images.length },
            )}
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.slideshow} {...bindSwipe()}>
                <div className={styles.frame}>
                    <div className={styles.slides}>
                        {images.map(({ id, imgSrc = null, alt = null }, index) => {
                            const valid =
                                index === currentIndex ||
                                index === currentIndex + 1 ||
                                index === currentIndex - 1;

                            return (
                                <div
                                    key={`slide-${id}-${index + 1}`}
                                    className={classNames([
                                        styles.slide,
                                        {
                                            [styles.previous]: index < currentIndex,
                                            [styles.current]: index === currentIndex,
                                            [styles.next]: index > currentIndex,
                                        },
                                    ])}
                                >
                                    {valid ? (
                                        <img
                                            className={styles.image}
                                            src={imgSrc}
                                            alt={intl.formatMessage(
                                                {
                                                    defaultMessage:
                                                        'Image {index} of {imageslength}: {alt}',
                                                    description: 'Image alt text',
                                                },
                                                {
                                                    index: index + 1,
                                                    imageslength: images.length,
                                                    alt: alt[locale],
                                                },
                                            )}
                                        />
                                    ) : null}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <Arrow
                    className={classNames([styles.arrow, styles.arrowLeft])}
                    focusable={focusable}
                    onClick={previousSlide}
                    aria-label={intl.formatMessage({
                        defaultMessage: 'Previous image',
                        description: 'Button label',
                    })}
                />
                <Arrow
                    className={classNames([styles.arrow, styles.arrowRight])}
                    focusable={focusable}
                    onClick={nextSlide}
                    aria-label={intl.formatMessage({
                        defaultMessage: 'Next image',
                        description: 'Button label',
                    })}
                />
                {/* <div aria-live="polite" aria-atomic className={styles.liveRegion}>
                    <FormattedMessage
                        defaultMessage="Image {index} of {imageslength}"
                        description="Live region"
                        values={{
                            index: currentIndex + 1,
                            imageslength: images.length,
                        }}
                    />
                </div> */}
            </div>
        </section>
    );
};

GalleryViewer.propTypes = propTypes;
GalleryViewer.defaultProps = defaultProps;

export default GalleryViewer;

import { useKeyboard } from '@folklore/hooks';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { useParams, useLocation } from 'react-router';

import MenuButton from '../buttons/Menu';
import { usePlaybackContext } from '../context/PlaybackContext';
import Menu from '../partials/Menu';

import styles from '../../styles/layouts/main.module.scss';

const propTypes = {
    children: PropTypes.node.isRequired,
    fullscreen: PropTypes.bool,
    currentRoute: PropTypes.string,
};

const defaultProps = {
    fullscreen: false,
    currentRoute: null,
};

function MainLayout({ fullscreen, children, currentRoute }) {
    const { audio = null } = usePlaybackContext();
    const { scene: sceneSlug = null } = useParams();
    const [menuOpened, setMenuOpened] = useState(false);
    const { unlock } = usePlaybackContext();

    const location = useLocation();
    const initialLocationRef = useRef(location);

    useEffect(() => {
        if (initialLocationRef.current !== location) {
            unlock();
            location.current = location;
        }
    }, [location, unlock]);

    const menuContainerRef = useRef(null);

    useEffect(() => {
        if (menuOpened) {
            disableBodyScroll(menuContainerRef.current);
        }
        return () => {
            if (menuOpened) {
                enableBodyScroll(menuContainerRef.current);
            }
        };
    }, [menuOpened]);

    // TODO: "m" keypress runs twice,
    // opening and then closing the menu. Fix.
    const keyMap = useMemo(
        () => ({
            m: () => {
                setMenuOpened(!menuOpened);
            },
            Escape: () => {
                if (menuOpened) {
                    setMenuOpened(false);
                    document.getElementById('content').getElementsByTagName('a')[0].focus();
                }
            },
        }),
        [menuOpened, setMenuOpened],
    );
    const onClickMenuButton = useCallback(
        () => setMenuOpened(!menuOpened),
        [setMenuOpened, menuOpened],
    );
    const onClickCloseMenu = useCallback(() => {
        setMenuOpened(false);
        document.getElementById('content').getElementsByTagName('a')[0].focus();
    }, [setMenuOpened]);
    useKeyboard(keyMap);

    const onMenuButtonFocus = useCallback(() => {
        setMenuOpened(true);
    }, [setMenuOpened]);

    const onClickSkipToContent = useCallback(() => {
        document.getElementById('content').getElementsByTagName('a')[0].focus();
    }, []);

    const onClickSkipToAudioControls = useCallback(() => {
        document.getElementById('audioControls').getElementsByTagName('a')[0].focus();
    }, []);

    useEffect(() => {
        if (menuOpened) {
            document.getElementById('menu').getElementsByTagName('a')[0].focus();
        }
    }, [menuOpened])

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.menuOpened]: menuOpened,
                    [styles.fullscreen]: fullscreen,
                },
            ])}
        >
            {fullscreen ? (
                <Helmet>
                    <style type="text/css">
                        {`html, body{ position: fixed; overflow: hidden;}`}
                    </style>
                </Helmet>
            ) : null}
            <header>
                <div className={styles.hidden} aria-hidden={menuOpened}>
                    <a
                        href="#content"
                        onClick={onClickSkipToContent}
                        aria-hidden={menuOpened}
                        tabIndex={menuOpened ? -1 : null}
                    >
                        <FormattedMessage
                            defaultMessage="Skip to content"
                            description="Button label"
                        />
                    </a>
                    {audio !== null ? (
                        <a
                            href="#audioControls"
                            onClick={onClickSkipToAudioControls}
                            aria-hidden={menuOpened}
                            tabIndex={menuOpened ? -1 : null}
                        >
                            <FormattedMessage
                                defaultMessage="Skip to audio controls"
                                description="Button label"
                            />
                        </a>
                    ) : null}
                </div>
                {!menuOpened ? (
                    <div className={styles.navigation}>
                        <span className={styles.navShadow} />
                        <MenuButton
                            className={styles.menuButton}
                            opened={menuOpened}
                            onClick={menuOpened ? onClickCloseMenu : onClickMenuButton}
                            onFocus={onMenuButtonFocus}
                        />
                    </div>
                ) : null}
            </header>
            <main id="content" className={styles.content} aria-hidden={menuOpened}>
                {React.cloneElement(children, {
                    menuOpened,
                })}
            </main>
            <div className={styles.menuContainer} ref={menuContainerRef}>
                <div
                    className={styles.inner}
                    style={{ display: !menuOpened ? 'none' : null }}
                    aria-hidden={!menuOpened}
                >
                    <Menu
                        className={styles.menu}
                        currentRoute={currentRoute}
                        focusable={menuOpened}
                        experience={sceneSlug !== null}
                        onClickClose={onClickCloseMenu}
                    />
                </div>
            </div>
        </div>
    );
}

MainLayout.propTypes = propTypes;
MainLayout.defaultProps = defaultProps;

export default MainLayout;

/* eslint-disable react/no-danger */

/* eslint-disable jsx-a11y/tabindex-no-positive */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { instructions } from '../../data';
import Button from '../buttons/Button';
import NextButton from '../buttons/Next';
import { textLogoFr, textLogoEn } from '../icons';
import PageTitle from '../partials/PageTitle';

import styles from '../../styles/pages/about.module.scss';

const propTypes = { menuOpened: PropTypes.bool, className: PropTypes.string };

const defaultProps = { menuOpened: false, className: null };

function InstructionsPage({ menuOpened, className }) {
    const url = useUrlGenerator();
    const { locale } = useIntl();
    const intl = useIntl();

    const { text } = instructions;

    const focusable = !menuOpened;

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <PageTitle
                title={intl.formatMessage({
                    defaultMessage: 'Instructions | Dream Voices',
                    description: 'Page title',
                })}
            />
            <div className={styles.inner}>
                <span className={styles.shadow} />
                <h1 className={styles.title}>
                    <FormattedMessage
                        defaultMessage="Instructions"
                        definition="Instructions title"
                    />
                </h1>
                <div className={styles.text} dangerouslySetInnerHTML={{ __html: text[locale] }} />

                <div className={styles.linkContainer}>
                    <NextButton href={url('intro')} focusable={focusable} className={styles.nextButton}>
                        <FormattedMessage defaultMessage="Introduction" definition="Button label" />
                    </NextButton>
                </div>
            </div>
            <Button href={url('home')} className={styles.logo} focusable={focusable}>
                <span className={styles.logoShadow} />
                <img
                    alt={intl.formatMessage({
                        defaultMessage: 'Dream Voices (back to Home)',
                        description: 'Logo label',
                    })}
                    src={locale === 'fr' ? textLogoFr : textLogoEn}
                    className={styles.logoIcon}
                />
            </Button>
        </div>
    );
}

InstructionsPage.propTypes = propTypes;
InstructionsPage.defaultProps = defaultProps;

export default InstructionsPage;

/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { back, backBlack } from '../icons';
import Button from './Button';

import styles from '../../styles/buttons/back.module.scss';

const propTypes = {
    children: PropTypes.node,
    shadow: PropTypes.bool,
    blackIcon: PropTypes.bool,

    className: PropTypes.string,
};

const defaultProps = {
    children: null,
    shadow: true,
    blackIcon: false,
    className: null,
};

function BackButton({ children, shadow, blackIcon, className, ...props }) {
    return (
        <Button
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            {...props}
        >
            <span className={styles.children}>
                {blackIcon ? (
                    <img src={backBlack} alt="" className={styles.icon} aria-hidden />
                ) : (
                    <img src={back} alt="" className={styles.icon} aria-hidden />
                )}
                {children}
            </span>
        </Button>
    );
}

BackButton.propTypes = propTypes;
BackButton.defaultProps = defaultProps;

export default BackButton;

import PropTypes from 'prop-types';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import IntlRouter from './IntlRouter';
import { NavigationProvider } from './context/NavigationContext';
import { PlaybackProvider } from './context/PlaybackContext';

import packageJson from '../../package.json';

const propTypes = {
    locales: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
    locales: packageJson.supportedLocales,
};

function Root({ locales }) {
    return (
        <BrowserRouter>
            <IntlRouter locales={locales}>
                <NavigationProvider>
                    <PlaybackProvider>
                        <App locales={locales} />
                    </PlaybackProvider>
                </NavigationProvider>
            </IntlRouter>
        </BrowserRouter>
    );
}

Root.propTypes = propTypes;
Root.defaultProps = defaultProps;

export default Root;

import { useState, useEffect } from 'react';

function useAudioCurrentTime(audio, frequency = 100) {
    const [currentTime, setCurrentTime] = useState(0);

    useEffect(() => {
        if (audio === null) {
            setCurrentTime(0);
            return () => {};
        }
        setCurrentTime(audio.getCurrentTime());
        const interval = setInterval(() => {
            setCurrentTime(audio.getCurrentTime());
        }, frequency);
        return () => {
            clearInterval(interval);
        };
    }, [audio, frequency]);

    return currentTime;
}

export default useAudioCurrentTime;

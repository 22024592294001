/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { next } from '../icons';
import Button from './Button';

import styles from '../../styles/buttons/next.module.scss';

const propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

const defaultProps = {
    children: null,
    className: null,
};

function NextButton({ className, children, ...props }) {
    return (
        <Button
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            {...props}
        >
            <span className={styles.children}>
                {children}
                <img src={next} alt="" className={styles.icon} aria-hidden />
            </span>
        </Button>
    );
}

NextButton.propTypes = propTypes;
NextButton.defaultProps = defaultProps;

export default NextButton;

import { useUrlGenerator } from '@folklore/routes';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { stories } from '../../data';
import PlayButton from '../buttons/Play';
import ReaderButton from '../buttons/Reader';
import { usePlaybackContext } from '../context/PlaybackContext';
import LogoEn from '../icons/LogoEn';
import LogoFr from '../icons/LogoFr';
import Circles from '../partials/Circles';
import PageTitle from '../partials/PageTitle';

import styles from '../../styles/pages/home.module.scss';

const propTypes = {
    menuOpened: PropTypes.bool,
};

const defaultProps = {
    menuOpened: false,
};

function HomePage({ menuOpened }) {
    const { play } = usePlaybackContext();

    const { locale } = useIntl();
    const intl = useIntl();

    const url = useUrlGenerator();
    const LogoIcon = locale === 'fr' ? LogoFr : LogoEn;

    // const colors = useMemo(() => stories.map(({ color }) => color), []);
    // const [colorIndex, setColorIndex] = useState(0);
    // const nextIndex = useCallback(
    //     () => setColorIndex(colorIndex === colors.length - 1 ? 0 : colorIndex + 1),
    //     [colorIndex, colors],
    // );
    // const onEnd = useCallback(() => nextIndex(), [nextIndex]);

    return (
        <div className={styles.container}>
            <PageTitle
                title={intl.formatMessage({
                    defaultMessage: 'Home | Dream Voices',
                    description: 'Page title',
                })}
            />
            <div className={styles.inner}>
                <div className={styles.logoContainer}>
                    <LogoIcon
                        // color={colors[colorIndex]}
                        // onEnd={onEnd}
                        duration={5000}
                        className={styles.logo}
                        aria-label={intl.formatMessage({
                            defaultMessage: 'Dream Voices',
                            description: 'Logo label',
                        })}
                    />
                </div>
                <div className={styles.circlesContainer}>
                    <Circles
                        stories={stories}
                        // color={colors[colorIndex]}
                        color="#45438E"
                        scene="home"
                        className={styles.circles}
                        aria-hidden
                    >
                        <div className={styles.textureBackground} />
                        <div className={styles.noiseBackground} />
                    </Circles>
                </div>
                <div className={styles.buttonContainer}>
                    <PlayButton
                        href={url('intro')}
                        className={styles.startButton}
                        focusable={!menuOpened}
                        aria-label={intl.formatMessage({
                            defaultMessage: 'Start experience (listen to the introduction)',
                            description: 'Button label',
                        })}
                        onClick={play}
                    >
                        <FormattedMessage
                            defaultMessage="Start experience"
                            description="Button label"
                        />
                    </PlayButton>
                </div>
                <ReaderButton
                    withCaptions
                    href={url('instructions')}
                    focusable={!menuOpened}
                    className={styles.instructions}
                >
                    <FormattedMessage defaultMessage="Instructions" description="Button label" />
                </ReaderButton>
            </div>
        </div>
    );
}

HomePage.propTypes = propTypes;
HomePage.defaultProps = defaultProps;

export default HomePage;
